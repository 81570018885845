import React from 'react';

import './PictureRows.scss';

const PictureRows = (props) => {
  return (
    <div className='picture-rows'>
        {
          props.contents.map((content, index) => (
            <img src={content.source} key={index} alt={`Row nr. ${index}`}/>
          ))
        }
    </div>
  )
}

export default PictureRows