import React from 'react';

import DropdownInput from './DropdownInput';
import TextInput from './TextInput';
import ColorInput from './ColorInput';

class SourceInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sourceType: props.sourceType != null ? props.sourceType : "img",
      source: props.source,
    }
  }

  get value() {
    return this.state
  }

  render() {
    const sourceTypeOptions = [
      { name: "Image", value: "img" },
      { name: "Vimeo", value: "vimeo" },
      { name: "Google Maps", value: "maps" },
      { name: "Colour", value: "color" }
    ]

    return (
      <div>
        <h2>{this.props.name}</h2>
        <DropdownInput
          options={this.props.options != null ? this.props.options : sourceTypeOptions}
          name={this.props.sourceTypeName}
          value={this.state.sourceType}
          onChange={async value => { await this.setState({ sourceType: value }); this.props.onChange(this.value) }}
        />
        {
          this.state.sourceType === "img" ?
            <div>
              <h3>Choose File</h3>
              <input
                name="Image"
                files={[this.state.source]}
                type="file"
                onChange={async e => {
                  if (e.target.files[0] != null) await this.setState({ source: e.target.files[0] })
                  this.props.onChange(this.value)
                }}
              />
              <img src={
                this.state.source != null ? typeof this.state.source.name == 'string' ? URL.createObjectURL(this.state.source) :
                  this.state.source : 'test_images/16:9_placeholder.png'
              } height="90px" width="160px" alt="Input" />
            </div>
            :
            this.state.sourceType === "color" ?
              <ColorInput
                name={this.props.colorName}
                initialInput={this.props.initialInput === "color"}
                value={this.state.source}
                onChange={async value => { await this.setState({ source: value }); this.props.onChange(this.value) }}
              />
              :
              <TextInput
                name={this.state.sourceType === "vimeo" ? "Insert valid Vimeo link" : "Enter Address"}
                value={this.state.source}
                onChange={async value => { await this.setState({ source: value }); this.props.onChange(this.value) }}
              />
        }
      </div>
    )
  }
}

export default SourceInput;
