import React from 'react';
import { withRouter } from 'react-router';

import { login } from "../../services/APIService";

import './Login.scss';

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(e) {
    e.preventDefault()
    await login(this.email.value, this.password.value).then(res => {
      localStorage.setItem('accessToken', res.data.data.auth.accessToken)
      this.props.onLogin()
    }).catch(e => this.props.onError())
    // if (res.status == 200) this.props.history.push('/')
  }

  render() {


    return(
      <div className="login-wrapper">
        <form onSubmit={this.onSubmit}>
          <div className="login">
            <h2>Email</h2>
            <input id="email_input" autoComplete="email" placeholder="Email..." ref={(c) => this.email = c}></input>
            <h2>Password</h2>
            <input id="password_input" autoComplete="current-password" type="password" placeholder="Password..." ref={(c) => this.password = c}></input>
            <button type="submit">Login</button>
          </div>
        </form>
      </div>
    )
  }
}

export default withRouter(Login);
