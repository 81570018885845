import React from 'react';
import { connect } from 'react-redux';

import { updateItemGraphQL, deleteItemGraphQL } from '../../../../redux/actions';


class AdminButtons extends React.Component {
  constructor(props) {
    super(props)
    delete props.item.parentType
  }

  render() {
    return(
      <div className="form_buttons">
        { !this.props.noDelete &&
          <button type="submit" onClick={ async () => {this.props.deleteItem(this.props.item.itemType, this.props.item)}}>Delete</button>
        }
        <button type="submit" onClick={ async () => {this.props.updateItem(this.props.item.itemType, this.props.item)}}>Save</button>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
    updateItem: (itemType, newItem) => dispatch(updateItemGraphQL(itemType, newItem)),
    deleteItem: (itemType, item) => dispatch(deleteItemGraphQL(itemType, item))
})

export default connect(null, mapDispatchToProps)(AdminButtons);
