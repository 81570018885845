import React from 'react';
import { connect } from 'react-redux';

import TextInput from '../../Inputs/TextInput';
import DropdownInput from '../../Inputs/DropdownInput';
import AdminButtons from '../utils/AdminButtons'

import { createItemGraphQL } from '../../../../redux/actions';


class ProjectForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...props.state,
      contentCreateType: "gallery"
    }
  }

  get value() {
    return this.state
  }

  render() {
    const contentTypeOptions = [
      {name: "Gallery", value: "gallery"},
      {name: "Picture Rows", value: "small_rows"},
      {name: "Text Box", value: "blue_box"},
      {name: "Hero", value: "feature"},
      {name: "Separation Line", value: "separation_line"}
    ]

    const flagTypeOptions = [
      {name: "No Award", value: null},
      {name: "Awarded", value: "awarded"},
      {name: "Finalist", value: "finalist"}
    ]

    let item = {...this.state}
    item.contentCreateType = undefined

    return (
      <div className="form page_form">
        <h2>{this.state.title != null ? this.state.title : "Project"}</h2>
        <a href={`/projects/${this.state.id}`} style={{color: "purple"}}><h3>Go to Project Page!</h3></a>
        <div className="inputs">
          <TextInput
            name="Project Name"
            value={this.state.title}
            onChange={value => this.setState({title: value})}
          />
          <TextInput
            name="Keyword"
            value={this.state.keyword}
            onChange={value => this.setState({keyword: value})}
          />
          <DropdownInput
            name="Award won?"
            options={flagTypeOptions}
            value={this.state.flagType}
            onChange={value => this.setState({ flagType: value })}
          />
        </div>
        <div>
          <h3>Add new Content</h3>
          <DropdownInput
           options={contentTypeOptions}
           name="Content Box Type"
           value={this.state.contentCreateType}
           onChange={value => this.setState({contentCreateType: value})}
           />
          <button type="submit" onClick={
               async () => {this.props.createProjectContent({itemType: "project_content", subType: this.state.contentCreateType}, this.props.state.id)}}
          >
            Add
          </button>
        </div>
        <AdminButtons item={item} />
      </div>
    )
  }
}


const mapDispatchToProps = dispatch => ({
    createProjectContent: (itemType, parentId) => dispatch(createItemGraphQL(itemType, parentId))
})

export default connect(null, mapDispatchToProps)(ProjectForm);
