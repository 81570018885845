import React from 'react';

import TextInput from '../../Inputs/TextInput';
import ColorInput from '../../Inputs/ColorInput';
import DropdownInput from '../../Inputs/DropdownInput';
import AdminButtons from '../utils/AdminButtons'


// Form applicable form title, bold_text, medium_text

class TextForm extends React.Component {
  constructor(props) {
    // props: textarea:bool, name:String
    super(props)

    this.state = {...props.state}
  }

  get value() {
    return this.state
  }

  render() {
    // Needs to be replaced later on. Whenever FontSelection is finished
    const fontOptions = [
      {name: "Bryant Bold", value: "Bryant Bold"},
      {name: "Bryant Medium", value: "Bryant Medium"}
    ]

     return (
       <div className="form text_form">
         <h2>{this.props.name}</h2>
         <div className="inputs">
           <DropdownInput
            options={fontOptions}
            name="Font"
            value={this.state.font}
            onChange={value => this.setState({font: value})}
            />
           <TextInput
            name="Size"
            value={this.state.size}
            onChange={value => this.setState({size: value})}
           />
           <TextInput
            name="Spacing"
            value={this.state.spacing}
            onChange={value => this.setState({spacing: value})}
           />
           <ColorInput
             name="Font Colour"
             initialInput={true}
             value={this.state.color}
             onChange={value => this.setState({color: value}) }
           />
           <ColorInput
             name="Highlight Colour"
             initialInput={true}
             value={this.state.highlightColor}
             onChange={value => this.setState({highlightColor: value}) }
           />
           <TextInput
            value={this.state.title}
            textarea={this.props.textarea}
            onChange={value => this.setState({title: value})}
           />
         </div>
         <AdminButtons noDelete={true} item={this.state} />
       </div>
     )
  }
}

export default TextForm;
