import React from 'react';


class ColorInput extends React.Component {
 constructor(props) {
   super(props)
   if (this.props.value != null && this.props.initialInput) {
     this.state = {
       color: this.props.value.slice(0, 6),
       opacity: Math.round(parseInt(this.props.value.slice(6, 8), 16) * 100 / 255)
     }
   }
   else this.state = {color: 'FFFFFF', opacity: 100.00}
 }

 get value () {
   let opacity_value = Math.round((this.state.opacity / 100) * 255).toString(16)
   if(opacity_value.length === 1) opacity_value = "0" + opacity_value
   return this.state.color + opacity_value
 }

 render() {
   return (
     <div>
      <h3>{this.props.name}</h3>
      <div>
        <span>Colour #</span>
        <input
          name="color"
          value={this.state.color}
          onChange={ async e => {await this.setState({color: e.target.value}); this.props.onChange(this.value)}}
        />
      </div>
      <div>
        <span>Opacity</span>
        <input
          name="opacity"
          type="number"
          value={this.state.opacity}
          step="1"
          min="0"
          max="100"
          onChange={async e => {await this.setState({opacity: e.target.value}); this.props.onChange(this.value) }}
        />%
      </div>
    </div>
   )
 }
}

export default ColorInput;
