import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import ContentRows from '../ContentRows/ContentRows'
import { filterProjectsByKeywords } from '../../utils/filters';

class KeywordResultsPage extends React.Component {

  render() {
    return (
      <>
        <ContentRows
          link_type="projects"
          contents={filterProjectsByKeywords(this.props.projects, this.props.match.params.keyword)}
        />
      </>
    )
  }
}

const mapStateToProps = state =>({
  projects: [...state.projects.items]
})

export default connect(mapStateToProps)(withRouter(KeywordResultsPage));
