import React from "react";
import { useSwiper, Swiper, SwiperSlide } from "swiper/react";

import "./ProjectContentGallery.scss";

const ArrowLeft = (props) => {
  const swiper = useSwiper();

  return (
    <img
      src="arrows/arrow_left.svg"
      className={`navigation_arrow left_navigation_arrow ${props.className}`}
      onClick={() => swiper.slidePrev()}
      alt="Left Navigation Arrow"
    />
  );
};

const ArrowRight = (props) => {
  const swiper = useSwiper();

  return (
    <img
      src="arrows/arrow_right.svg"
      className={`navigation_arrow right_navigation_arrow ${props.className}`}
      onClick={() => swiper.slideNext()}
      alt="Right Navigation Arrow"
    />
  );
};

const ProjectContentGallery = (props) => {
  const isGalleryShort = props.galleryContent.length < 3;
  return (
    <Swiper
      className={`project_content_gallery ${
        isGalleryShort ? "swiper-centered" : ""
      }`}
      initialSlide={Math.ceil(props.galleryContent.length / 2) - 1}
      slidesPerView={"auto"}
    >
      <ArrowLeft className={`${isGalleryShort ? "hidden" : ""}`} />
      <div className="project_content_gallery__images">
        {props.galleryContent.map((content, index) => {
          let SlideContent = (props) => (
            <img
              src="test_images/16:9_placeholder.png"
              key={index}
              className="project_content_gallery__image"
              alt="Gallery Content Placeholder Image"
            />
          );
          if (content.sourceType === "img") {
            SlideContent = (props) => (
              <img
                src={content.source}
                key={index}
                className="project_content_gallery__image"
                alt="Gallery Content"
              />
            );
          } else if (content.sourceType === "vimeo") {
            let vimeoId =
              content.source.split("/")[content.source.split("/").length - 1];
            SlideContent = (props) => (
              <div className="project_content_gallery__iframe">
                <iframe
                  title="Vimeo Gallery Content"
                  src={`https://player.vimeo.com/video/${vimeoId}?autoplay=0&loop=1&autopause=0`}
                  frameborder="0"
                  allow="autoplay; fullscreen"
                  allowfullscreen
                ></iframe>
              </div>
            );
          }
          return (
            <SwiperSlide key={index}>
              <SlideContent />
            </SwiperSlide>
          );
        })}
      </div>
      <ArrowRight className={`${isGalleryShort ? "hidden" : ""}`} />
    </Swiper>
  );
};

export default ProjectContentGallery;
