import React from 'react';
import styled from 'styled-components';

import { convertPtToRem } from '../../utils/functions';

import './ProjectTextBox.scss';

class ProjectTextBox extends React.Component {

  render() {
    const BoldText = styled.h1`
      ${this.props.boldText.font != null ? `font: ${this.props.boldText.font} !important;` : ''}
      ${this.props.boldText.color != null ? `color: #${this.props.boldText.color} !important;` : ''}
      ${this.props.boldText.size != null ? `font-size: ${convertPtToRem(this.props.boldText.size)}rem !important;` : ''}
      &:hover {
        ${this.props.boldText.highlightColor != null ? `color: #${this.props.boldText.highlightColor} !important;` : ''}
      }
    `

    const MediumText = styled.p`
      ${this.props.mediumText.font != null ? `font: ${this.props.mediumText.font} !important;` : ''}
      ${this.props.mediumText.size != null ? `font-size: ${convertPtToRem(this.props.mediumText.size)}rem !important;` : ''}
      ${this.props.mediumText.color != null ? `color: #${this.props.mediumText.color} !important;` : ''}
      &:hover {
        ${this.props.mediumText.highlightColor != null ? `color: #${this.props.mediumText.highlightColor} !important;` : ''}
      }
    `

    return (
        <div className='project_page__text_box'>
          <BoldText dangerouslySetInnerHTML={{__html: this.props.boldText.title}} />
          <MediumText dangerouslySetInnerHTML={{__html: this.props.mediumText.title}} />
        </div>
    )
  }
}

export default ProjectTextBox;
