import React from 'react';

class TextInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: props.value }
  }

  get value() {
    return this.state.value
  }

  render() {
    return (
      <div>
        <h3>{this.props.name}</h3>
        {this.props.textarea ?
          <textarea
            name={this.props.name}
            rows="5" cols="20"
            maxLength="1000"
            value={this.state.value.replace('<br />', '\n') || ''}
            onChange={async e => {
              await this.setState({ value: e.target.value })
              this.props.onChange(this.state.value.replace(/\n\r?/g, '<br />'))
            }}
          /> :
          <input
            name={this.props.name}
            value={this.state.value || ''}
            onChange={async e => {
              await this.setState({ value: e.target.value })
              this.props.onChange(this.state.value)
            }}
          />
        }
      </div>)
  }
}


export default TextInput;
