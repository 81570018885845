import React from 'react';
import { connect } from 'react-redux';

import SourceInput from '../../Inputs/SourceInput';
import AdminButtons from '../utils/AdminButtons';

import { createItemGraphQL } from '../../../../redux/actions';

class ProjectContentForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...props.state
    }
  }

  render() {
    // const contentTypeOptions = [
    //   {name: "Gallery", value: "gallery"},
    //   {name: "Picture Rows", value: "small_rows"},
    //   {name: "Text Box", value: "blue_box"},
    //   {name: "Hero", value: "feature"},
    //   {name: "Separation Line", value: "separation_line"}
    // ]

    return (
      <div className="form project_content_form">
        <h2>{this.state.subType[0].toUpperCase() + this.state.subType.slice(1)}</h2>
        <div className="inputs">

           {(this.state.subType === 'gallery' || this.state.subType === 'small_rows' ) &&
             <button type="submit" onClick={
                  async () => {this.props.createSource(this.props.state.id)}}
             >
               Add Source
             </button>
           }
           {(this.state.subType === 'feature' || this.state.subType === 'blue_box') &&
             <SourceInput
               name={this.state.subType === 'blue_box' ? "Background" : "Hero Display"}
               sourceType={this.state.sourceType}
               source={this.state.source}
               colorName={this.state.subType === 'blue_box' ? "Background Colour" : "Hero Colour"}
               initialInput={this.state.sourceType}
               onChange={value => this.setState({source: value.source, sourceType: value.sourceType})}
               />
           }
        </div>
        <AdminButtons item={this.state} />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
    createSource: (parentId) => dispatch(createItemGraphQL('source', parentId)),
})

export default connect(null, mapDispatchToProps)(ProjectContentForm);
