import * as APIService from '../services/APIService';
import store from './store';
import { filterItemsById } from '../utils/filters';

const CREATE_ITEM = 'CREATE_ITEM'
const UPDATE_ITEM = 'UPDATE_ITEM'
const SORT_ITEMS = 'SORT_ITEMS'
const DELETE_ITEM = 'DELETE_ITEM'
const FETCH_ITEMS = 'FETCH_ITEMS'
const FETCH_DETAILS = 'FETCH_DETAILS'
const NAV = 'NAV'

const actionTypes = {
  CREATE_ITEM,
  UPDATE_ITEM,
  SORT_ITEMS,
  DELETE_ITEM,
  FETCH_ITEMS,
  FETCH_DETAILS,
  NAV
}

function createItem(item, itemType) {
  return {
    type: CREATE_ITEM,
    item,
    itemType
  }
}

function updateItem(newItem, itemType) {
  return {
    type: UPDATE_ITEM,
    item: newItem,
    itemType
  }
}

function sortItemsAction(sortedChildren, itemType) {
  return {
    type: SORT_ITEMS,
    sortedChildren,
    itemType
  }
}

function deleteItem(id, success, itemType) {
  return {
    type: DELETE_ITEM,
    itemType,
    id,
    success
  }
}


function deleteWithWholeItem(item, success, itemType) {
  return {
    type: DELETE_ITEM,
    itemType,
    item,
    success
  }
}

function fetchItems(items, itemType, shallow = false) {
  return {
    type: FETCH_ITEMS,
    itemType,
    items,
    shallow
  }
}

function fetchDetails(item, itemType) {
  return {
    type: FETCH_DETAILS,
    itemType,
    item
  }
}



function createItemGraphQL(itemType, parentId) {
  return function (dispatch) {
    return APIService.createItem(itemType, parentId).then(
      response => {
        dispatch(createItem(response.data.data.createItem.item, response.data.data.createItem.item.itemType))
      }
    )
  }
}

function createItemWithSourceGraphQL(itemType, parentId, file) {
  return function (dispatch) {
    return APIService.createItem(itemType, parentId, file).then(
      response => dispatch(createItem(response.data.data.createItem.item, response.data.data.createItem.item.itemType))
    )
  }
}

function updateItemGraphQL(itemType, newItem) {
  return function (dispatch) {
    return APIService.updateItem(newItem).then(
      (response) => {
        return dispatch(updateItem(response.data.data.updateItem.item, response.data.data.updateItem.item.itemType));
      }
    )
  }
}

function sortItemsGraphQL(parentId, itemType, fromPosition, toPosition) {
  return function(dispatch) {
    return APIService.sortItems(parentId, itemType, fromPosition, toPosition).then(
      (response) => {
        const children = response.data.data.sort.children
        dispatch(sortItemsAction(children, itemType))
        return children
      }
    )
  }
}

function deleteItemGraphQL(itemType, item) {
  return function (dispatch) {
    return APIService.deleteItem(itemType, item.id, item.parentId).then(
      response => {
        if (itemType !== 'project_content') dispatch(deleteItem(item.id, response.status === 200, itemType))
        else dispatch(deleteWithWholeItem(item, response.status === 200, itemType))
      }
    )
  }
}

function fetchItemsGraphQL(params) {
  // params:
  // {parentId: string, id: string, userIsParent: boolean, children: [{params}]}
  return function(dispatch) {
    return APIService.getItems(params).then(
      response => {
        if (response.data.data.items.items.length > 0 && response.data.data.items.lastEvaluatedKey != null && (params.itemType === 'project' || params.itemType === 'page')) {
          if (filterItemsById(store.getState()[params.itemType + 's'].items, JSON.parse(response.data.data.items.lastEvaluatedKey.replaceAll("'", '"')).id.S) == null) {
            dispatch(fetchItemsGraphQL({ ...params, lastEvaluatedKey: response.data.data.items.lastEvaluatedKey }))
          }
        }
        dispatch(fetchItems(response.data.data.items, params.itemType, false))
        return response.data.data.items
      }
    )
  }
}

function fetchItemsGraphQLShallow(params) {
  return function(dispatch) {
    return APIService.getItemsShallow(params).then(
      response => {
        if (response.data.data.items.items.length > 0 && response.data.data.items.lastEvaluatedKey != null && (params.itemType === 'project' || params.itemType === 'page')) {
          if (filterItemsById(store.getState()[params.itemType + 's'].items, JSON.parse(response.data.data.items.lastEvaluatedKey.replaceAll("'", '"')).id.S) == null) {
            dispatch(fetchItemsGraphQLShallow({ ...params, lastEvaluatedKey: response.data.data.items.lastEvaluatedKey }))
          }
        }
        dispatch(fetchItems(response.data.data.items, params.itemType, false))
        return response.data.data.items
      }
    )
  }
}

function fetchDetailsGraphQL(id, parentId, itemType = 'page', subType = null) {
  return function(dispatch) {
    return APIService.getDetails(id, parentId, itemType, subType).then( 
      response => {
        dispatch(fetchDetails(response.data.data.items.items[0], response.data.data.items.items[0].itemType))
        // Returning the whole item
        return response.data.data.items.items[0] 
      }  
    )
  }
}

export default actionTypes;

export {
  createItem,
  updateItem,
  sortItemsAction,
  deleteItem,
  createItemGraphQL,
  createItemWithSourceGraphQL,
  updateItemGraphQL,
  sortItemsGraphQL,
  deleteItemGraphQL,
  fetchItemsGraphQL,
  fetchItemsGraphQLShallow,
  fetchDetailsGraphQL
}
