import axios from 'axios';

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  config.Authorization = localStorage.getItem('accessToken')
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

const querySubSelection = `
id
parentId
link
itemType
title
source
sourceType
keyword
flagType
color
highlightColor
font
size
subType
order
`

axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    if(config.url.includes('graphql')) config.withCredentials = true;
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

async function executeGraphQLQuery (queryString) {
  return axios({
    url: process.env.REACT_APP_GRAPHQL_URL,
    method: 'post',
    data: {
      query: queryString
    },
    headers: {
	     'Access-Control-Allow-Origin': '*',
	  },
  })
}

async function login(email, password) {
  const queryString = `
    mutation{
      auth(email: "${email}", password: "${password}"){
        accessToken,
        refreshToken,
        user {
          id
        }
      }
    }
  `

  let res = await executeGraphQLQuery(queryString)
  return res
}

function getQueryStringShallow(params) {
  return `
    items(
      ${params.itemType != null ? `itemType: "${params.itemType}", ` : '' }
      ${params.subType != null ? `subType: "${params.subType}", ` : '' }
      ${params.parentId != null ? `parentId: "${params.parentId}", ` : '' }
      ${params.id != null ? `id: "${params.id}", ` : ''}
      ${params.userIsParent != null ? `userIsParent: ${params.userIsParent}, ` : ''}
      ${params.lastEvaluatedKey != null ? `lastEvaluatedKey: "${params.lastEvaluatedKey}", ` : ''}
      ${params.unsorted != null ? `unsorted: ${params.unsorted}, ` : ''}
      limit: 100,
      shallow: true
    ) {
      lastEvaluatedKey
      items {
        ${querySubSelection}
      }
    }
  `
}

function getQueryString(params) {
  return `
      items(
            ${params.itemType != null ? `itemType: "${params.itemType}", ` : '' }
            ${params.parentId != null ? `parentId: "${params.parentId}", ` : '' }
            ${params.id != null ? `id: "${params.id}", ` : ''}
            ${params.userIsParent != null ? `userIsParent: ${params.userIsParent}, ` : ''}
            ${params.lastEvaluatedKey != null ? `lastEvaluatedKey: "${params.lastEvaluatedKey}", ` : ''}
            ${params.limit != null ? `limit: ${params.limit}, ` : ''}
            ${params.unsorted != null ? `unsorted: ${params.unsorted}, ` : ''}
      ) {
        lastEvaluatedKey
        items {
          ${querySubSelection}
          children {
            ${querySubSelection}
            children {
              ${querySubSelection}
              children {
                ${querySubSelection}
              }
            }
          }
        }
      }
  `
}

async function getItems(params) {
  const queryString = `
    query {
      ${ getQueryString(params) }
    }
  `

  let res = await executeGraphQLQuery(queryString)
  return res
}

async function getItemsShallow(params) {
  const queryString = `
    query {
      ${ getQueryStringShallow(params) }
    }
  `

  let res = await executeGraphQLQuery(queryString)
  return res
}

async function getDetails(id, parentId, itemType, subType) {
  //TODO: userIsParent shouldn't be necessary as we are fetching by ID here. Leaving it for now.
  const userIsParent = itemType in ['page', 'project', 'general']
  const queryString = `
    query {
      ${ getQueryString({id, parentId, itemType, subType, userIsParent: userIsParent} ) }
    }
  `

  let res = await executeGraphQLQuery(queryString)
  return res
}

async function createItem(itemType, parentId, file) {
  let mutationString = ''
  if(file != null){
    mutationString = 'mutation($file: Upload) '
  }
  else mutationString = 'mutation '

  let subType = null
  if (typeof itemType == 'object') {
    subType = itemType.subType
    itemType = itemType.itemType
  }
  const queryString = `
    ${mutationString} {
      createItem(itemType: "${itemType}",
       parentId: "${parentId != null ? parentId : ''}",
       subType: "${subType != null ? subType : ''}",
       ${file != null ? `file: $file`: ''}) {
        item {
          ${querySubSelection}
          children {
            ${querySubSelection}
            children {
              ${querySubSelection}
              children {
                ${querySubSelection}
              }
            }
          }
        }
      }
    }
  `

  if(file != null) {
    const formData = new FormData()
    formData.append("variables", JSON.stringify({"file": null}))
    formData.append("query", queryString)
    formData.append("map", JSON.stringify({"file": ["variables.file"]}))
    formData.append("file", file)
    let res = await axios({
      url: process.env.REACT_APP_GRAPHQL_URL,
      method: 'post',
      data: formData,
      headers: {'Content-Type': `multipart/mixed; boundary=${formData._boundary}`}
    })
    return res
  }
  else {
    let res = await executeGraphQLQuery(queryString)
    return res
  }
}

async function updateItem(params) {
  Object.entries(params).map(([key,value],i) => {
    if(value == null || value === '') delete params[key]
    return null
  })

  let mutationString = ''
  if(params.sourceType === 'img' && typeof params.source === 'object' && params.source.type != null){
    mutationString = 'mutation($file: Upload) '
    params.file = '$file'
  }
  else mutationString = 'mutation '

  const queryString = `
    ${mutationString} {
      updateItem(${(() => {
        return Object.entries(params).map(([key,value],i) => {
          if (key !== "file" && key !== 'chosen' && key !== 'selected' && key !== 'children' && key !== 'items' && value !== '') return ` ${key}: "${value != null ? `${value}` : ''}"`
          else if(key === 'file') return ` ${key}: ${value != null ? value : ''}`
          return null
        })
      })()}) {
        item {
          ${querySubSelection}
          children {
            ${querySubSelection}
          }
        }
      }
    }
  `
  if(params.sourceType === 'img' && typeof params.source === 'object' && params.source.type != null){
    const formData = new FormData()
    formData.append("variables", JSON.stringify({"file": null}))
    formData.append("query", queryString)
    formData.append("map", JSON.stringify({"file": ["variables.file"]}))
    formData.append("file", params.source)
    let res = await axios({
      url: process.env.REACT_APP_GRAPHQL_URL,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': `multipart/mixed; boundary=${formData._boundary}`
      }
    })
    return res
  }
  else {
    let res = await executeGraphQLQuery(queryString)
    return res
  }
}

async function sortItems(parentId, itemType, fromPosition, toPosition) {
  const queryString = `
    mutation {
      sort(parentId: "${parentId}", itemType: "${itemType}", fromPosition: ${fromPosition}, toPosition: ${toPosition}) {
        children {
          ${querySubSelection}
        }
      }
    }
  `

  let res = await axios({
    url: process.env.REACT_APP_GRAPHQL_URL,
    method: 'post',
    data: {
      query: queryString
    },
    headers: {
      'Content-Type': 'application/json'
    }
  })
  return res
}

async function deleteItem(itemType, id, parentId) {
  const queryString = `
    mutation {
      deleteItem(id: "${id}", parentId: "${parentId}", itemType: "${itemType}") {
        success
      }
    }
  `
  let res = await executeGraphQLQuery(queryString)
  return res
}

async function isLoggedIn() {
  let res = await axios({
    url: process.env.REACT_APP_GRAPHQL_URL,
    method: 'post',
    data: {
      query: `mutation {
          isLoggedIn {
            isLoggedIn
          }
        }
      `
    },
    headers: {
       'Access-Control-Allow-Origin': '*',
    },
  })
  if (res.status > 300) return false
  else return res.data.data.isLoggedIn.isLoggedIn
}


export {
  getItems,
  getItemsShallow,
  getDetails,
  login,
  isLoggedIn,
  createItem,
  updateItem,
  sortItems,
  deleteItem
}
