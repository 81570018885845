import React from 'react';

import TextInput from '../../Inputs/TextInput';
import AdminButtons from '../utils/AdminButtons'

class BoxForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {...props.state}
  }


  render() {
    return (
      <div className="form box_form">
        <h2>Box</h2>
        <div className="inputs">
          <TextInput name="Name" value={this.state.title} onChange={value => this.setState({title: value})}/>
          <TextInput name="Link" value={this.state.link} onChange={value => this.setState({link: value})} />
        </div>
        <AdminButtons item={this.state} />
      </div>
    )
  }
}

export default BoxForm;
