import React from 'react';

import SourceInput from '../../Inputs/SourceInput';
import AdminButtons from '../utils/AdminButtons'


class ProjectSourceForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {...props.state}
  }

  get value() {
    return this.state
  }

  render() {
    return(
      <div className="form project_feature_form">
        <h2>Background</h2>
        <div className="inputs">
          <SourceInput
            sourceType={this.state.sourceType}
            source={this.state.source}
            colorName={this.state.itemType === 'project_feature' ? "Feature Colour" : "Colour"}
            initialInput={this.state.sourceType}
            onChange={value => this.setState({source: value.source, sourceType: value.sourceType})}
            />
        </div>
        <AdminButtons noDelete={this.props.noDelete} item={this.state} />
      </div>
    )
  }
}

export default ProjectSourceForm;
