import React from 'react';

import TextInput from '../../Inputs/TextInput';
import ColorInput from '../../Inputs/ColorInput';
import AdminButtons from '../utils/AdminButtons'


class SeparationLineForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {...props.state}
  }

  get value() {
    return this.state
  }

  render() {
    const noDelete = this.props.noDelete != null ? this.props.noDelete : true
     return (
       <div className="form separation_line_form">
         <h2>Separation Line</h2>
         <div className="inputs">
           <TextInput
            name="Size"
            value={this.state.size}
            onChange={value => this.setState({size: value})}
           />
           <ColorInput
             name="Colour"
             initialInput={true}
             value={this.state.color}
             onChange={value => this.setState({color: value}) }
           />
           <ColorInput
             name="Highlight Colour"
             initialInput={true}
             value={this.state.highlightColor}
             onChange={value => this.setState({highlightColor: value}) }
           />
         </div>
         <AdminButtons noDelete={noDelete} item={this.state} />
       </div>
     )
  }
}

export default SeparationLineForm;
