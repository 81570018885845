import React from 'react';
import styled from 'styled-components';

import { convertPtToRem } from '../../../../utils/functions';

import './ContentOverlayTransparent.scss';

class ContentOverlayTransparent extends React.Component {

  render() {

    const BoldText = styled.h1`
      ${this.props.boldText.font != null ? `font: ${this.props.boldText.font} !important;` : ''}
      ${this.props.boldText.size != null ? `font-size: ${convertPtToRem(this.props.boldText.size)}rem !important;` : ''}
      ${this.props.boldText.color != null ? `color: #${this.props.boldText.color} !important;` : ''}
      ${this.props.boldText.spacing != null ? `line-height: #${this.props.boldText.spacing} !important;` : ''}
      &:hover {
        ${this.props.boldText.highlightColor != null ? `color: #${this.props.boldText.highlightColor} !important;` : ''}
      }
    `

    const MediumText = styled.p`
      ${this.props.mediumText.font != null ? `font: ${this.props.mediumText.font} !important;` : ''}
      ${this.props.mediumText.size != null ? `font-size: ${convertPtToRem(this.props.mediumText.size)}rem !important;` : ''}
      ${this.props.mediumText.color != null ? `color: #${this.props.mediumText.color} !important;` : ''}
      ${this.props.mediumText.spacing != null ? `line-height: #${this.props.mediumText.spacing} !important;` : ''}
      &:hover {
        ${this.props.mediumText.highlightColor != null ? `color: #${this.props.mediumText.highlightColor} !important;` : ''}
      }
    `

    return (
      <div className='content_overlay_transparent_wrapper'>
        <div className='content_overlay_transparent'>
          <BoldText className='content_overlay_transparent__header' dangerouslySetInnerHTML={{__html: this.props.boldText.title}} />
          <MediumText className='content_overlay_transparent__text' dangerouslySetInnerHTML={{__html: this.props.mediumText.title}} />
        </div>
      </div>
    )
  }
}

export default ContentOverlayTransparent;
