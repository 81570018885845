import { createStore, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
// import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from './reducers';


// Log only in development
var middlewares = [thunk]
if (process.env.REACT_APP_NODE_ENV === "development" && !process.env.REACT_APP_NO_REDUX_LOGGING) {
  const { logger } = require("redux-logger");
  middlewares.push(logger)
}


const store = createStore(
  rootReducer,
  applyMiddleware(...middlewares)
);

export default store;
