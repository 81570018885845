import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import ProjectTextColumn from '../ProjectTextColumn/ProjectTextColumn';
import ProjectTextBox from '../ProjectTextBox/ProjectTextBox';
import ProjectFeatureContent from '../ProjectFeatureContent/ProjectFeatureContent';
import ProjectContentGallery from '../ProjectContentGallery/ProjectContentGallery';
import PictureRows from '../PictureRows/PictureRows';

import { fetchDetailsGraphQL } from '../../redux/actions';
import { filterItemsById, filterItemsByItemType } from '../../utils/filters';

import './ProjectPage.scss';
import { orderItems } from '../../utils/functions';

class ProjectPage extends React.Component {

  render() {
    const projectId = this.props.match.params.id;
    let project = filterItemsById(this.props.projects, projectId)
    if (project != null) {
      if (project.children != null) {
        return (
          <div className='project_page'>
              {filterItemsByItemType(project.children.slice(0), 'project_content').sort(orderItems).map(
                (projectContent, index) => {
                    if (projectContent.subType === "feature") {
                      if(projectContent.children != null && projectContent.children.length > 0) {
                        let boldText = filterItemsByItemType(projectContent.children[0].children, 'bold_text')[0]
                        let mediumText = filterItemsByItemType(projectContent.children[0].children, 'medium_text')[0]
                        return (
                          <div className='project_page__feature_row row' key={index}>
                            <ProjectTextColumn
                              mediumText={mediumText}
                              boldText={boldText}
                            />
                            <ProjectFeatureContent
                              content_type={projectContent.sourceType}
                              source={projectContent.source}
                            />
                          </div>
                        )
                      }
                      else return (
                        <span key={index}>Loading...</span>
                      )
                    }
                    else if (projectContent.subType === "blue_box") {
                      return <ProjectTextBox
                              key={index}
                              boldText={filterItemsByItemType(projectContent.children[0].children, 'bold_text')[0]}
                              mediumText={filterItemsByItemType(projectContent.children[0].children, 'medium_text')[0]}
                              />
                    }
                    else if (projectContent.subType === "separation_line") {
                      return <div className='border_lines' key={index}></div>
                    }
                    else if (projectContent.subType === "gallery") {
                      return <ProjectContentGallery galleryContent={projectContent.children} key={index}/>
                    }
                    else if (projectContent.subType === "small_rows") {
                      return <PictureRows contents={projectContent.children} key={index}/>
                    }
                  return null
                }
              )}
          </div>
        )
      }
      else {
        this.props.fetchProjectDetails(project.id, project.parentId)
        return (
          <></>
        )
      }
    }
    else {
      return (
        <></>
      )
    }
  }
}

const mapDispatchToProps = dispatch => ({
  fetchProjectDetails: (id, parentId) => dispatch(fetchDetailsGraphQL(id, parentId, 'project')),
})

const mapStateToProps = state => ({
  projects: [...state.projects.items]
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectPage));
