import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ReactSortable } from 'react-sortablejs';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse";

import './AdminSidebar.scss';
import './SidebarItem.scss';
import { fetchDetailsGraphQL, sortItemsGraphQL } from '../../redux/actions';
import AdminForm from './AdminForm';
import { orderItems } from '../../utils/functions';
import { filterItemsByItemType, filterItemsById, findItem } from '../../utils/filters';
import { ORDERED_ITEM_TYPES } from '../../utils/constants';

class SidebarItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: true
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
      if (prevState.orderUpdated) {
        return {...prevState, orderUpdated: false}
      }
     else if (nextProps.item != null || prevState.item !== nextProps.item){
       if ((nextProps.item.itemType === 'box' && nextProps.item.subType === null) || (nextProps.item.itemType === 'project_feature')) {
          let box = JSON.parse(JSON.stringify(nextProps.item))
          box.items = [
            filterItemsByItemType(box.children, "background")[0],
            {
              title: "Textfield layout",
              items: [
                filterItemsByItemType(box.children, "text_field")[0],
                filterItemsByItemType(box.children, "separation_line")[0],
                filterItemsByItemType(box.children, "title")[0]
              ]
            },
            {
              title: "Text",
              items: [
                filterItemsByItemType(box.children, "bold_text")[0],
                filterItemsByItemType(box.children, "medium_text")[0],
              ]
            }
          ]
          box.children = null
          return {item: box}
       }
       else if (nextProps.item.itemType === 'project') {
         let project = JSON.parse(JSON.stringify(nextProps.item))
         if (project.children != null) {
          project.items = [
            {
              title: "Box Layouts",
              items: filterItemsByItemType(project.children, 'project_feature')
            },
            {
              title: "Project Elements",
              children: filterItemsByItemType(project.children.slice(0), 'project_content').sort(orderItems).reverse()
            }
          ]
         }
         project.children = null
         return {item: project}
       }
       else if (nextProps.item.itemType === 'project_content') {
         let projectContent = JSON.parse(JSON.stringify(nextProps.item))
         projectContent.children.map(item => (
           item.parentType = projectContent.subType
         ))
         if(projectContent.subType === 'separation_line') projectContent.children = null
         if(projectContent.subType === 'blue_box' || projectContent.subType === 'feature') projectContent.children = [projectContent.children[0]]
         return {item: projectContent}
       }
       else return { item: nextProps.item };
    }
    else return null;
  }

  toggleCollapse() {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  onClick(e) {
    if (['page', 'project'].includes(this.props.item.itemType) && this.props.item.children == null) {
      this.props.fetchItemDetails(this.props.item.id, this.props.item.parentId)
    }
    this.toggleCollapse();
  }

  onDragStartLink(event, item) {
    event.dataTransfer.setData(
      'linkedItem',
      JSON.stringify(item)
    )
  }


  render() {
    let expandIcon;
    if (this.props.item != null) {
      expandIcon = !this.state.collapsed ? (
        <ExpandLessIcon
          className={
            "sidebar-item-expand-arrow sidebar-item-expand-arrow-expanded"
          }
        />
      ) : (
        <ExpandMoreIcon className="sidebar-item-expand-arrow" />
      );
    }

    // Change
    let name = null
    switch(this.state.item.itemType) {
      case 'page':
        if(this.state.item.subType === 'landing') name = "Landing Page"
        break
      case 'general':
        switch(this.state.item.subType) {
          case 'nav':
            name = "Menue Bar"
            break
          case 'main':
            name = "Logo / Site Name"
            break
          case 'links':
            name = "Themes"
            break
          default:
            break
        }
        break
      case 'separation_line':
        name = "Separation Line"
        break
      case 'bold_text':
        name = "Text Headlines"
        break
      case 'medium_text':
        name = "Text Sublines"
        break
      case 'title':
        name = "Headline"
        break
      case 'project_feature_child':
        switch(this.state.item.subType) {
          case 'bold_text':
            name = "Text Headlines"
            break
          case 'medium_text':
            name = "Text Sublines"
            break
          case 'title':
            name = "Headline"
            break
          default:
            break
        }
        break
      case 'project_content':
        switch(this.state.item.subType) {
          case 'blue_box':
            name = "Text Box"
            break
          case 'feature':
            name = "Hero"
            break
          case 'small_rows':
            name = "Picture Rows"
            break
          default:
            break
        }
        break
      case 'source':
        if (this.state.item.parentType === 'blue_box' || this.state.item.parentType === 'feature') name = "Text"
        break
      case 'box':
        let project = filterItemsById(this.props.projects, this.state.item.link?.split('/')[this.state.item.link?.split('/').length - 1])
        if (this.state.item.subType === 'project' && project != null) name =  "Box: " + project.title
        break
      default:
        break
    }

    name = name != null ?
     name :
     this.state.item !== 'form' ?
     this.state.item.title ? this.state.item.title :
     this.state.item.subType ? this.state.item.subType[0].toUpperCase() + this.state.item.subType.slice(1) :
     this.state.item.itemType ? this.state.item.itemType[0].toUpperCase() + this.state.item.itemType.slice(1) :
     "No name given" :
     this.state.item.title
    if (ORDERED_ITEM_TYPES.includes(this.state.item.itemType)) name = name + ' ' + (this.state.item.order + 1)
    return (
      <>
        <ListItem
          className={`sidebar-item ${!this.state.collapsed ? "yellow" : ""}`}
          onDragOver={e => {if (this.props.onDragOver != null) this.props.onDragOver(e)}}
          onDrop={e => {if (this.props.onDrop != null) this.props.onDrop(e)}}
          onClick={e => {if (this.state.item != null) {this.onClick(e) }}}
          style={{backgroundColor: this.state.item.id === this.props.pageID ? 'red' : this.props.depth % 2 > 0 ? 'rgb(117,117,117,0.75)' : 'rgb(66,66,66,0.75)'}}
          button
          dense
        >
          <div
            className="sidebar-item-content"
            onDragStart={e => this.onDragStartLink(e, this.state.item)}
            draggable
          >
            <div className="sidebar-item-text">
              {name}
            </div>
          </div>
          {expandIcon}
        </ListItem>
        <Collapse in={!this.state.collapsed} timeout="auto" unmountOnExit>
          { this.state.item.itemType != null ?
            <AdminForm items={[this.state.item]} parentItemType={this.props.parentItemType}/> :
            null
          }
          {Array.isArray(this.state.item.items) ? (
            <List disablePadding dense>
              {this.state.item.items.map((subItem, index) => (
                (<React.Fragment key={`${subItem.title}${index}`}>
                  {subItem === "divider" ? (
                    <Divider style={{ margin: "6px 0" }} />
                  ) : (
                    <SidebarItem
                      depth={this.props.depth + 1}
                      item={subItem}
                      pageID={this.props.pageID}
                      onDragOver={this.props.onDragOver}
                      onDrop={this.props.onDrop}
                      createBoxContainer={this.props.createBoxContainer}
                      createBox={this.props.createBox}
                      fetchSources={this.props.fetchSources}
                      parentItemType={this.props.item.itemType != null ? this.props.item.itemType : this.props.parentItemType}
                      parentItem={this.state.item}
                      fetchItemDetails={this.props.fetchItemDetails}
                      projects={this.props.projects}
                      sortItems={this.props.sortItems}
                    />

                  )}
                </React.Fragment>)
              ))}
            </List> )
            : null }
            {Array.isArray(this.state.item.children) ? (
              <List disablePadding dense>
                <ReactSortable
                 list={this.state.item.children}
                //  onEnd={
                //   async (e) => {
                //     e.stopPropagation() 
                //     const children = this.state.item.children
                //     const fromPosition = children.length - 1 - e.oldIndex 
                //     const toPosition = children.length - 1 - e.newIndex

                //     const newChildren = this.props.sortItems(children[fromPosition].parentId, children[fromPosition].itemType, fromPosition, toPosition)
                //     // this.setState({item: {...this.state.item, children: newChildren}, orderUpdated: true})
                //   }
                //  }
                 setList={async (newState) => {
                  if (newState.some((item, index) => item.order !== index && ORDERED_ITEM_TYPES.includes(item.itemType)) ) {
                     
                   let firstIndex = null
                   let lastIndex = null

                   for (let i = 0; i < newState.length; i++){
                     if (newState[i].order !== i && firstIndex === null)  {
                       firstIndex = i
                     } else if (firstIndex !== null) {
                       if (i + 1 < newState.length) {
                         if (newState[i+1].order === i + 1) {
                           lastIndex = i
                           break
                         }
                       } else {
                         lastIndex = i
                       }
                     }
                   } 

                   const toPosition =  Math.abs(newState[firstIndex].order - firstIndex) > 1 ? firstIndex : lastIndex
                   const fromPosition = toPosition === firstIndex ? lastIndex : firstIndex

                    const sortedItems = await this.props.sortItems(newState[firstIndex].parentId, newState[firstIndex].itemType,
                      newState.length - 1 - fromPosition,
                      newState.length - 1 - toPosition)
                    newState = sortedItems.map((item) => {
                      const prevItem = findItem(newState, item.id)
                      return {...item, children: prevItem.children}
                    })
                  }
                  this.setState({item: {...this.state.item, children: newState}, orderUpdated: true})
                }}
                >
                  {[...this.state.item.children].reverse().map((subItem, index) => (
                    <React.Fragment key={`${subItem.title}${index}`}>
                      <SidebarItem
                        depth={this.props.depth + 1}
                        item={subItem}
                        pageID={this.props.pageID}
                        onDragOver={this.props.onDragOver}
                        onDrop={this.props.onDrop}
                        createBoxContainer={this.props.createBoxContainer}
                        createBox={this.props.createBox}
                        fetchSources={this.props.fetchSources}
                        parentItemType={this.props.item.itemType != null ? this.props.item.itemType : this.props.parentItemType}
                        parentItem={this.state.item}
                        fetchItemDetails={this.props.fetchItemDetails}
                        projects={this.props.projects}
                        sortItems={this.props.sortItems}
                      />
                    </React.Fragment>
                  ))}
                </ReactSortable>

            </List>)
            : null }
        </Collapse>
      </>
    );
  }
}

const mapDispatchToPropsSidebarItem = dispatch => ({
  sortItems: (parentId, itemType, fromPosition, toPosition) => dispatch(sortItemsGraphQL(parentId, itemType, fromPosition, toPosition)),
  fetchItemDetails: (id, parentId, itemType) => dispatch(fetchDetailsGraphQL(id, parentId, itemType)),
})

const mapStateToPropsSidebarItem = state => ({
  projects: [...state.projects.items]
})


const wrappedSideBarItem = connect(mapStateToPropsSidebarItem, mapDispatchToPropsSidebarItem)(withRouter(SidebarItem))

export default wrappedSideBarItem
