import React from 'react';
import styled from 'styled-components';

import { convertPtToRem } from '../../../../utils/functions';

import './ContentOverlayTransparentBig.scss';

class ContentOverlayTransparentBig extends React.Component {

  render() {
    const title = this.props.header_text

    const bold_text = this.props.content_bold
    const BoldText = styled.h1`
      ${bold_text.font != null ? `font: ${bold_text.font} !important;` : ''}
      ${bold_text.size != null ? `font-size: ${convertPtToRem(bold_text.size)}rem !important;` : ''}
      ${bold_text.color != null ? `color: #${bold_text.color} !important;` : ''}
      ${bold_text.highlightColor != null ? `&:hover { color: #${bold_text.color} !important; }` : ''}
    `
    const medium_text = this.props.content_medium
    const MediumText = styled.span`
      ${medium_text.font != null ? `font: ${medium_text.font} !important;` : ''}
      ${medium_text.size != null ? `font-size: ${convertPtToRem(medium_text.size)}rem !important;` : ''}
      ${medium_text.color != null ? `color: #${medium_text.color} !important;` : ''}
      ${medium_text.highlightColor != null ? `&:hover { color: #${medium_text.color} !important; }` : ''}
    `

    return (
      <a href={this.props.link_type === 'keyword' ? `/results/${title.title}` : this.props.link}>
        <div className='content_overlay_transparent_big_wrapper'>
          <div className='content_overlay_transparent_big'>
            <div className='content_overlay_transparent_big__container'>
              <div>
                <BoldText className='content_overlay_transparent_big__bold_text'>
                  {bold_text.title.toUpperCase()}
                </BoldText>
              </div>
              <MediumText>{medium_text.title.toUpperCase()}</MediumText>
            </div>
            {this.props.flag == null ? null :
             (this.props.flag === 'awarded') ? <img src='flags/awarded_flag.svg'
                className='content_overlay_transparent_big__flag' alt="Awarded Flag"/> :
             (this.props.flag === 'finalist') ? <img src='flags/finalist_flag.svg'
                className='content_overlay_transparent_big__flag' alt="Finalist Flag"/> : null
            }
          </div>
        </div>
      </a>
    )
  }
}

export default ContentOverlayTransparentBig;
