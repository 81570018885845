import React from 'react';
import axios from 'axios';

import './ProjectFeatureContent.scss';

const parse = require('html-react-parser');


class ProjectFeatureContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if(this.props.content_type === 'vimeo') this.getVimeoEmbed().then(html => this.setState({html}));
  }

  async getVimeoEmbed () {
    let res = await axios.get(`https://vimeo.com/api/oembed.json?url=${this.props.source}`).catch(error => console.log(error));
    return parse(res.data.html);
  }

  render() {
    return (
      <div className={`project_feature_content ${this.props.content_type === 'vimeo' ? 'responsive-video' : ''}`}>
        {this.props.content_type === 'vimeo' ? this.state.html : <img src={this.props.source} alt="Project Feature"/>}
      </div>
    )
  }
}

export default ProjectFeatureContent;
