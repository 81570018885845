import React from "react";

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";

import './AdminSidebar.scss';
import Login from '../Login/Login';
import SidebarItem from './SidebarItem';
import { isLoggedIn } from '../../services/APIService';
import { createItemGraphQL } from '../../redux/actions';
import { filterItemsBySubType } from '../../utils/filters';



class AdminSidebar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isVisible: true,
      loggedIn: false,
      displayError: false
    }
  }

  componentDidMount() {
    isLoggedIn().then(status => this.setState({ loggedIn: status }))
  }


  render () {
    const pathname = this.props.location.pathname
    const parent_id = pathname.slice(pathname.lastIndexOf('/') + 1, pathname.length)
    return (
      <div className="admin_sidebar">
        <button onClick={e => this.setState({isVisible: !this.state.isVisible})} className="admin_sidebar__hide_button">Hide Admin Panel</button>
        {!this.state.loggedIn && this.state.isVisible && (
          <div>
            <span style={{"color": "red"}}>YOU ARE NOT LOGGED IN!!!</span>
            <Login onLogin={() => this.setState({loggedIn: true, displayError: false})} onError={() => this.setState({displayError: true})}/>
          </div>
        )}
        {
          this.state.displayError  && (
            <div>
              <span style={{"color": "red"}}>Wrong Login Information</span>
            </div>
          )
        }
        {this.state.loggedIn && <span style={{color: "green"}}> You are logged in</span>}
        <div className={`admin_sidebar__list_wrapper ${!this.state.isVisible ? "hidden" : ""}`}>
        <List disablePadding dense>
          {this.props.header.map((sidebarItem, index) => (
            <React.Fragment key={`${sidebarItem.title}${index}`}>
              {sidebarItem === "divider" ? (
                <Divider style={{ margin: "6px 0" }} />
              ) : (
                <SidebarItem
                  depthStep={this.props.depthStep}
                  depth={0}
                  expanded={this.props.expanded}
                  pageID={parent_id}
                  item={sidebarItem}
                />
              )}
            </React.Fragment>
          ))}
        </List>
        <SidebarItem
          depthStep={this.props.depthStep}
          depth={0}
          expanded={this.props.expanded}
          pageID={parent_id}
          item={this.props.landingPage}
        />
        <List disablePadding dense>
          {this.props.pages.map((sidebarItem, index) => (
            <React.Fragment key={`${sidebarItem.title}${index}`}>
              {sidebarItem === "divider" ? (
                <Divider style={{ margin: "6px 0" }} />
              ) :
              (
                <SidebarItem
                  depthStep={this.props.depthStep}
                  depth={0}
                  expanded={this.props.expanded}
                  pageID={parent_id}
                  item={sidebarItem}
                />
              )}
            </React.Fragment>
          ))}
        </List>
        <div>
          <button onClick={ e => this.props.createpage(process.env.REACT_APP_USER_ID) }>New Page</button>
          <button onClick={ e => this.props.createBox( parent_id !== '' ? parent_id : this.props.landingPage.id) }>New Box</button>
          <button onClick={ e => this.props.createProject(process.env.REACT_APP_USER_ID) }>New Project</button>
        </div>
        <List disablePadding dense>
          {this.props.projects.map((sidebarItem, index) => (
            <React.Fragment key={`${sidebarItem.title}${index}`}>
              {sidebarItem === "divider" ? (
                <Divider style={{ margin: "6px 0" }} />
              ) : (
                <SidebarItem
                  depthStep={this.props.depthStep}
                  depth={0}
                  expanded={this.props.expanded}
                  pageID={parent_id}
                  item={sidebarItem}
                  createBoxContainer={this.props.createBoxContainer}
                  createBox={this.props.createBox}
                />
              )}
            </React.Fragment>
          ))}
        </List>
      </div>
      </div>
    )
  }
}


const mapStateToProps = state => { 
  const general = [...state.general]
  const pages = [...state.pages.items]
  const projects = [...state.projects.items]
  return {
    header: [
      "divider",
      {
        title: "Header",
        items: [
          filterItemsBySubType(general, 'nav').length > 0 ? filterItemsBySubType(general, 'nav')[0] : {title: '...Loading'},
          filterItemsBySubType(general, 'main').length > 0 ? filterItemsBySubType(general, 'main')[0] : {title: '...Loading'},
          filterItemsBySubType(general, 'links').length > 0 ? filterItemsBySubType(general, 'links')[0] : {title: '...Loading'},
        ]
      },
      "divider"
    ],
    landingPage: filterItemsBySubType(pages, 'landing').length > 0 ?
    filterItemsBySubType(pages, 'landing')[0] : {title: '...Loading'},
    pages: [
      "divider",
      {
        title: "Pages",
        items: pages.filter(item => item.subType !== 'landing')
      },
      "divider",
    ],
    projects: [
      "divider",
      {
        title: "Projects",
        children: projects
      },
      "divider",
    ],
  }
}

const mapDispatchToPropsAdminsideBar = dispatch => ({
  createpage: (parentId) => dispatch(createItemGraphQL('page', parentId)),
  createBox: (parentId) => dispatch(createItemGraphQL('box', parentId)),
  createBoxContainer: (parentId) => dispatch(createItemGraphQL('project_content', parentId)),
  createProject: (parentId) => dispatch(createItemGraphQL('project', parentId)),
})

export default connect(mapStateToProps, mapDispatchToPropsAdminsideBar)(withRouter(AdminSidebar));
