const filterItemsByParentId = (items, parentId) => {
  let newItems = []
  let oldItems = [...items]
  oldItems.map((item, i) => {
    if(item != null) {
      if (item.parentId === parentId) newItems.push(item)
    }
    return null
  })
  return newItems
}

const filterItemsById = (items, id) => {
  for (let i=0; i < items.length; i++) {
    if (items[i].id === id) return items[i]
  }
  return null
}

const filterItemsByItemType = (items, itemType) => {
  let newItems = []
  let oldItems = [...items]
  oldItems.map((item, i) => {
    if (item.itemType === itemType) newItems.push(item)
    return null
  })
  return newItems
}

const filterItemsBySubType = (items, subType) => {
  let newItems = []
  let oldItems = [...items]
  oldItems.map((item, i) => {
    if (item.subType === subType) newItems.push(item)
    return null
  })
  return newItems
}

const filterProjectsByKeywords = (projects, keywords) => {
  let keywordProjects = []
  let originalProjects = [...projects]
  // eslint-disable-next-line
  const keywordArray = keywords.split(/[\s\,]/g)
  originalProjects.map((project, i) => {
    if (project.keyword != null && keywords != null){
      // eslint-disable-next-line
      const projectKeywordArray = project.keyword.split(/[\s\,]/g)
      if (keywordArray.filter(value => projectKeywordArray.includes(value)).length > 0) keywordProjects.push(project)
    }
    return null
  })
  return keywordProjects
}

const filterItemsByFlagType = (items) => {
  let flaggedItems = []
  let originalItems = [...items]
  originalItems.map((item, i) => {
    if (item.flagType != null ){
      flaggedItems.push(item)
    }
    return null
  })
  return flaggedItems
}

const getIndexOfItemType = (items, itemType) => {
  for (let i=0; i < items.length; i++) {
    if (items[i].itemType === itemType) return i
  }
}

const getIndexOfId = (items, id) => {
  for (let i=0; i < items.length; i++) {
    if (items[i].id === id) return i
  }
}

const findItem = (items, id) => {
  if(items.length === 0) return

    // return element if found else collect all children(or other nestedKey) array and run this function
    return items.find(d => d.id === id)
        || findItem(items.flatMap(d => d.children || []), id)
        || null
}

export {
  filterItemsByParentId,
  filterItemsById,
  getIndexOfItemType,
  getIndexOfId,
  filterItemsByItemType,
  filterProjectsByKeywords,
  filterItemsByFlagType,
  filterItemsBySubType,
  findItem
}
