import React from 'react';

import TextInput from '../../Inputs/TextInput';
import SourceInput from '../../Inputs/SourceInput';
import DropdownInput from '../../Inputs/DropdownInput';
import AdminButtons from '../utils/AdminButtons'


class PageForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {...props.state}
  }

  get value() {
    return this.state
  }

  render() {
    const pageTypeOptions = [
      {name: "About", value: "big_transparent"},
      {name: "News", value: "news"},
      {name: "Awards", value: "awards"},
      {name: "Clients", value: "keyword"},
      {name: "Standard", value: "standard"}
    ]
    return (
      <div className="form page_form">
        <h2>{this.state.title != null ? this.state.title : "Page"}</h2>
        <div className="inputs">

          { this.state.subType !== 'landing' &&
            <>
              <TextInput
                name="Page Name"
                value={this.state.title}
                onChange={value => this.setState({title: value})}
                />
              <DropdownInput
                options={pageTypeOptions}
                name="Layout"
                value={this.state.subType != null ? this.state.subType : "standard"}
                onChange={value => this.setState({subType: value})}
                />
            </>
          }
          <SourceInput
            name="Page Background"
            sourceType={this.state.sourceType}
            source={this.state.source}
            colorName="Page Background Colour"
            initialInput={this.state.sourceType}
            onChange={value => this.setState({source: value.source, sourceType: value.sourceType})}
            />
        </div>
        <AdminButtons item={this.state} noDelete={this.state.subType === 'landing'}/>
      </div>
    )
  }
}

export default PageForm;
