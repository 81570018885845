import React from 'react';

class DropdownInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {value: props.value}
  }

  get value() {
    return this.state.value
  }

  render() {
    return(
      <div>
        <h3>{this.props.name}</h3>
        <select
          name={this.props.name}
          value={this.state.value != null ? this.state.value : ''}
          onChange={async e => {await this.setState({value: e.target.value}); this.props.onChange(this.state.value) }}
          >
          {
            this.props.options.map((option, index) => (
              <option value={option.value} key={index}>{option.name}</option>
            ))
          }
          </ select>
      </div>
    )
  }
}

export default DropdownInput;
