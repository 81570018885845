import React from 'react';
import styled from 'styled-components';

import { convertPtToRem } from '../../../../utils/functions';

import './ContentOverlay.scss';

class ContentOverlay extends React.Component {

  render() {
    const title = this.props.header_text
    const separation_line = this.props.separation_line
    const Title = styled.h2`
      ${title.font != null ? `font: ${title.font} !important;` : ''}
      ${title.size != null ? `font-size: ${convertPtToRem(title.size)}rem !important;` : ''}
      ${title.color != null ? `color: #${title.color} !important;` : '' }
      ${separation_line.color != null ? `border-color: #${separation_line.color.slice(0, 6)} !important;` : ''}
      &:hover {
                ${title.highlightColor != null ? `color: #${title.highlightColor} !important;` : '' }
                ${separation_line.highlightColor != null ? `border-color: #${separation_line.highlightColor.slice(0, 6)} !important;` : ''}
              }
    `

    const bold_text = this.props.content_bold
    const BoldText = styled.h1`
      ${bold_text.font != null ? `font: ${bold_text.font} !important;` : ''}
      ${bold_text.size != null ? `font-size: ${convertPtToRem(bold_text.size)}rem !important;` : ''}
      ${bold_text.color != null ? `color: #${bold_text.color} !important;` : ''}
      ${bold_text.highlightColor != null ? `&:hover { color: #${bold_text.color} !important; }` : ''}
    `
    const medium_text = this.props.content_medium
    const MediumText = styled.span`
      ${medium_text.font != null ? `font: ${medium_text.font} !important;` : ''}
      ${medium_text.size != null ? `font-size: ${convertPtToRem(medium_text.size)}rem !important;` : ''}
      ${medium_text.color != null ? `color: #${medium_text.color} !important;` : ''}
      ${medium_text.highlightColor != null ? `&:hover { color: #${medium_text.color} !important; }` : ''}
    `

    return (
      // TODO: Was originally "this.props.link_type === 'keyword' ? `/results/${title.title}` : this.props.link"
      // Behaviour might need to be restored in the future
      <a href={this.props.link}>
        <div className='content_overlay_wrapper'>
          <div className='content_overlay'>
            <Title className='content_overlay__header'>{title.title ? title.title.toUpperCase() : ''}</Title>
            <div className='content_overlay__container'>
              <BoldText dangerouslySetInnerHTML={{__html:bold_text.title}} />
              <MediumText dangerouslySetInnerHTML={{__html: medium_text.title}} />
            </div>
            {this.props.flag == null ? null :
             (this.props.flag === 'awarded') ? <img src='flags/awarded_flag.svg'
                className='content_overlay__flag' alt="Awarded Flag"/> :
             (this.props.flag === 'finalist') ? <img src='flags/finalist_flag.svg'
                className='content_overlay__flag' alt="Finalist Flag"/> : null
            }
          </div>
        </div>
      </a>
    )
  }
}

export default ContentOverlay;
