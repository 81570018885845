import React from 'react';

import ColorInput from '../../Inputs/ColorInput';
import AdminButtons from '../utils/AdminButtons'


class TextFieldForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {...props.state}
  }

  get value() {
    return this.state
  }

  render() {
     return (
       <div className="form text_field_form">
         <h2>Textfield</h2>
         <div className="inputs">
           <ColorInput
             name="Colour"
             initialInput={true}
             value={this.state.color}
             onChange={value => this.setState({color: value}) }
           />
           <ColorInput
             name="Highlight Colour"
             initialInput={true}
             value={this.state.highlightColor}
             onChange={value => this.setState({highlightColor: value}) }
           />
         </div>
         <AdminButtons noDelete={true} item={this.state} />
       </div>
     )
  }
}

export default TextFieldForm;
