import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import styled  from 'styled-components';

import { filterItemsBySubType } from '../../utils/filters'

import './NavBar.scss';


class NavBar extends React.Component {
  render() {
    let NavLink
    let HighLightedNavLink
    let currentPageID
    if (this.props.item != null) {
      const color = this.props.item.color
      const highlightColor = this.props.item.highlightColor
      currentPageID = this.props.location.pathname.includes('page') ? this.props.location.pathname.split('pages/').at(-1) : null

      NavLink = styled.span`
        color: #${color} !important;
        &:hover { color: #${highlightColor} !important;}
      `

      HighLightedNavLink = styled.span`
        color: #${highlightColor} !important;
        &:hover { color: #${highlightColor} !important;}
      `
    }
    return (  
      <div className='nav_bar'>
        <Link to='/'>
          <img src='header_logo.png' className='nav_bar__header_logo' alt="Header Logo"/>
        </Link>
        <div className='nav_bar__pages'>
          {this.props.pages.map((page, index) => {

            if (page.subType !== 'landing') {
              const pageTitle = page.title ? page.title.toUpperCase() : 'New page'
              const isHighlighted = currentPageID != null ? currentPageID == page.id : false
              const LinkComponent = isHighlighted ? HighLightedNavLink : NavLink
              return (
                <Link key={index} to={{
                  pathname: `/pages/${page.id}`,
                }}>
                  { LinkComponent &&
                    <LinkComponent className='nav_bar__page'>
                      {pageTitle}
                    </LinkComponent>
                  }
                </Link>
              )
            }
            return null
          })}
        </div>
        <input type='text' placeholder='SEARCH' />
      </div>
    )
  }
}



const mapStateToProps = state => {
  return {
    pages: [...state.pages.items].slice(1, 7),
    item: filterItemsBySubType([...state.general], "links")[0]
  }
}

export default connect(mapStateToProps)(withRouter(NavBar));
