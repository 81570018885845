import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import ContentRows from '../ContentRows/ContentRows';
import { filterItemsById, filterItemsBySubType } from '../../utils/filters';
import { fetchDetailsGraphQL } from '../../redux/actions';
import { OVERLAY_TYPE_MAPPING } from '../../utils/constants';


class StandardPage extends React.Component {
  render() {
    const page = filterItemsById(this.props.pages, this.props.match.params.id) || filterItemsBySubType(this.props.pages, 'landing')[0] || {}
    const style = {}
    if (this.props.page != null && this.props.app != null) {
      switch (page.sourceType) {
        case 'img':
          style.backgroundImage = "url(" + page.source + ")"
          break
        case 'color':
          style.background = "#" + page.color
          break
        default:
          break
      }
    }
    let boxes = []
    if (Object.keys(page).length > 0){
      if (page.children != null) boxes = page.children
      else this.props.fetchPage(page.id, page.parentId, page.subType)
    } 
    return (
      <>
        <ContentRows link_type={page.subType} overlay_type={OVERLAY_TYPE_MAPPING[page.subType]} contents={boxes} style={style} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  pages: [...state.pages.items],
})

const mapDispatchToProps = dispatch => ({
  fetchPage: (id, parentId, subType) => dispatch(fetchDetailsGraphQL(id, parentId, 'page', subType)),
})


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StandardPage));
