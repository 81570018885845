const BOX_ORGANISATION_TYPES = [
    'carousel_bot',
    'feature_bot',
    'small_rows_bot',
    'big_rows_bot'
]

const BOX_CHILDREN_TYPES = [
    'medium_text',
    'bold_text',
    'separation_line',
    'title',
    'background',
    'text_field'
]

const ITEM_TYPES = [
    'user',
    'header',
    'page',
    'box',
]

const ORDERED_ITEM_TYPES = [
  'project',
  'page',
  'box',
  'source',
  'project_content'
]

const OVERLAY_TYPE_MAPPING = {
  'standard': 'main',
  'landing': 'main',
  'main': 'main',
  'news': 'news',
  'awards': 'awards',
  'keyword': 'main'
}

const AWARD_TYPE_OPTIONS = [
  'awarded',
  'finalist '
]

ITEM_TYPES.concat(BOX_ORGANISATION_TYPES)
ITEM_TYPES.concat(BOX_CHILDREN_TYPES)

export {
  ITEM_TYPES,
  BOX_CHILDREN_TYPES,
  BOX_ORGANISATION_TYPES,
  ORDERED_ITEM_TYPES,
  OVERLAY_TYPE_MAPPING,
  AWARD_TYPE_OPTIONS
}
