import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import ContentContainer from '../ContentContainer/ContentContainer';

import './ContentRows.scss';

const pageSize = 8

class ContentRows extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1
    }
  }

  loadMore(page) {
    // this.setState({page: page + 1})
  }

  render() {
    const displayBoxes = []
    if (this.props.link_type === 'projects') {
      
      this.props.contents.slice(0).reverse().map((project, i) => {
        displayBoxes.push({
          children: [
            { itemType: 'title', title: project.title },
            { itemType: 'background' },
            { itemType: 'separation_line' },
            { itemType: 'bold_text' },
            { itemType: 'medium_text' },
            { itemType: 'text_field' }
          ],
          flagType: project.flagType,
          link: `/projects/${project.id}`
        })
        return null
      })
      return (
        <div className='content_page'>
          {displayBoxes.map((content, index) => {
            return <ContentContainer link_type={this.props.link_type} item={content} index={index} key={index} />
          })}
        </div>
      )
    }
    else{
      this.props.contents.slice(0).reverse().map((content, index) => {
        // if (index < this.state.page * pageSize) {
          displayBoxes.push(<ContentContainer
            style=
            {{
              margin: (this.props.overlay_type === 'transparent' && (index - 1) % 3 === 0) ? '0rem 1.4rem 1.4rem 1.4rem' :
                this.props.overlay_type === 'transparent' ? '0rem 0rem 1.4rem 0rem' : ''
            }}
            overlay_type={this.props.overlay_type}
            link_type={this.props.link_type}
            item={content}
            index={index}
            key={index}
            noDnD={this.props.noDnD}
          />)
        // }
        return content
      })

    } 
    return (
      <div className='content_page_scroll_parent' ref={(ref) => this.scrollParentRef = ref}>
        <InfiniteScroll
          className='content_page' 
          style={this.props.style}
          pageStart={1}
          loadMore={this.loadMore.bind(this)}
          hasMore={true || false}
          loader={<div className="loader" key={0}>Loading ...</div>}
          useWindow={false}
          getScrollParent={() => this.scrollParentRef}
        >
          {displayBoxes}
        </InfiniteScroll>
      </div>
      // <InfiniteScroll
      //   className='content_page' 
      //   style={this.props.style}
      //   pageStart={1}
      //   loadMore={this.loadMore.bind(this)}
      //   hasMore={true || false}
      //   loader={<div className="loader" key={0}>Loading ...</div>}
      //   useWindow={false}
      // >
      //   {displayBoxes}
      // </InfiniteScroll>
    )
  }
}



export default ContentRows;
