import React from "react";

import "./ImprintPage.scss";

const ImprintPage = (props) => {
  return (
    <div className="imprint">
      <h3 className="P4">
        <span className="T1">IMPRINT</span>
        <span className="T1" />
      </h3>
      <h3 className="P4">
        <span className="T3">ACCORDING TO § 5 TMG </span>
        <span className="T3" />
      </h3>
      <h3 className="P4">
        <span className="T5">
          M.A.P. FILMPRODUKTIONS UND -BERATUNGS HOLDING GMBH
        </span>
        <span className="T8">
          <br />
          SCHULSTRASSE 4<br />
          80634 MUNICH
          <br />
          GERMANY
        </span>
        <span className="T5" />
      </h3>
      <h3 className="P4">
        <span className="T3">REPRESENTED BY: SILVIA PIPER</span>
        <span className="T3" />
      </h3>
      <h3 className="P4">
        <span className="T2">DISCLAIMER</span>
        <span className="T2" />
      </h3>
      <h3 className="P4">
        <span className="T4">1. CONTENT</span>
        <span className="T4" />
      </h3>
      <p className="P5">
        <span className="T9">
          The author reserves the right not to be responsible for the
          topicality, correctness, completeness or quality of the information
          provided. Liability claims regarding damage caused by the use of any
          information provided, including any kind of information which is
          incomplete or incorrect,will therefore be rejected. All offers are
          not-binding and without obligation. Parts of the pages or the complete
          publication including all offers and information might be extended,
          changed or partly or completely deleted by the author without separate
          announcement.
        </span>
        <span className="T9" />
      </p>
      <p className="P9"> </p>
      <p className="P5">
        <span className="T4">2. REFERRALS AND LINKS</span>
        <span className="T13" />
      </p>
      <p className="P5">
        <span className="T9">
          The author is not responsible for any contents linked or referred to
          from his pages – unless he has full knowledge of illegal contents and
          would be able to prevent the visitors of his site from viewing those
          pages. If any damage occurs by the use of information presented there,
          only the author of the respective pages might be liable, not the one
          who has linked to these pages. Furthermore the author is not liable
          for any postings or messages published by users of discussion boards,
          guestbooks or mailing lists provided on his page.
        </span>
        <span className="T9" />
      </p>
      <h3 className="P1">
        <span className="T7">3. COPYRIGHT</span>
        <span className="T7" />
      </h3>
      <p className="P2">
        <span className="T11">
          The author intended not to use any copyrighted material for the
          publication or, if not possible, to indicate the copyright of the
          respective object. The copyright for any material created by the
          author is reserved. Any duplication or use of objects such as images,
          diagrams, sounds or texts in other electronic or printed publications
          is not permitted without the author’s agreement.
        </span>
        <span className="T11" />
      </p>
      <p className="P3"> </p>
      <h3 className="P1">
        <span className="T7">4. PRIVACY POLICY</span>
        <span className="T7" />
      </h3>
      <p className="P2">
        <span className="T11">
          If the opportunity for the input of personal or business data (email
          addresses, name, addresses) is given, the input of these data takes
          place voluntarily. The use and payment of all offered services are
          permitted – if and so far technically possible and reasonable –
          without specification of any personal data or under specification of
          anonymized data or an alias. The use of published postal addresses,
          telephone or fax numbers and email addresses for marketing purposes is
          prohibited, offenders sending unwanted spam messages will be punished.
        </span>
        <span className="T11" />
      </p>
      <h3 className="P4">
        <span className="T4">5. LEGAL VALIDITY OF THIS DISCLAIMER</span>
        <span className="T4" />
      </h3>
      <p className="P5">
        <span className="T9">
          This disclaimer is to be regarded as part of the internet publication
          which you were referred from. If sections or individual terms of this
          statement are not legal or correct, the content or validity of the
          other parts remain uninfluenced by this fact.
        </span>
        <span className="T9" />
      </p>
      <h3 className="P4">
        <span className="T1">PRIVACY POLICY</span>
        <span className="T1" />
      </h3>
      <p className="P8"> </p>
      <p className="P5">
        <span className="T14">PRIVACY STATEMENT</span>
        <span className="T14" />
      </p>
      <p className="P6">
        <span className="T10"> </span>
        <span className="T10" />
      </p>
      <p className="P5">
        <span className="T10">
          Data protection is of a particularly high priority for the management
          of M.A.P. Filmproduktions und -beratungs Holding GmbH. The use of the
          pages of our website is mostly possible without any indication of
          personal data; however, if a data subject wants to use special
          enterprise services via our website, processing of personal data can
          become necessary. If the processing of personal data is necessary and
          there is no legal basis for such processing, we generally obtain
          consent from the data subject.
        </span>
        <span className="T10" />
      </p>
      <p className="P5">
        <span className="T10">
          The processing of personal data, such as the name, address, e-mail
          address, or telephone number of a data subject shall always be in
          line 
        </span>
        <span className="T9">
          with the General Data Protection Regulation (GDPR), and in accordance
          with the country-specific data protection regulations applicable to{" "}
        </span>
        <span className="T10">
          M.A.P. Filmproduktions und -beratungs Holding GmbH
        </span>
        <span className="T9">
          . By means of this data protection declaration, our enterprise would
          like to inform the general public of the nature, scope, and purpose of
          the personal data we collect, use and process. Furthermore, data
          subjects are informed, by means of this data protection declaration,
          of the rights to which they are entitled.
        </span>
        <span className="T10" />
      </p>
      <p className="P5">
        <span className="T9">As the controller, </span>
        <span className="T10">
          M.A.P. Filmproduktions und -beratungs Holding GmbH
        </span>
        <span className="T9">
          {" "}
          implemented numerous technical and organizational measures to ensure
          the most complete protection of personal data processed through this
          website. However, Internet-based data transmissions may in principle
          have security gaps, so absolute protection may not be guaranteed. For
          this reason, every data subject is free to transfer personal data to
          us via alternative means, e.g. by telephone.
        </span>
        <span className="T9" />
      </p>
      <p className="P9"> </p>
      <p className="P5">
        <span className="T9">1.  Who is the Controller?</span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T9">Controller for this website is </span>
        <span className="T10">
          M.A.P. Filmproduktions und -beratungs Holding GmbH
        </span>
        <span className="T9">, </span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T9">Schulstraße 4, , 80634 Munich, Germany</span>
        <span className="T9" />
      </p>
      <p className="P9"> </p>
      <p className="P9"> </p>
      <p className="P9"> </p>
      <p className="P9"> </p>
      <p className="P5">
        <span className="T9">2.What do we do with your personal data?</span>
        <span className="T9" />
      </p>
      <p className="P9"> </p>
      <p className="P5">
        <span className="T9">A. If you use our website </span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T9">
          The provision of this website requires the processing of personal
          data, such as your IP address, in order to access the content
          displayed on this website.{" "}
        </span>
        <span className="T9" />
      </p>
      <p className="P6">
        <span className="T9"> </span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T12">(1) Legal Basis</span>
        <span className="T12" />
      </p>
      <p className="P5">
        <span className="T9">
          The handling of your personal data for the provision of this website
          and for communication via this website takes place on the legal basis
          of our legitimate interest. For the provision of this website it is
          technically necessary that we process certain personal data (e.g. the
          IP address). For your communication with us it is necessary that we
          process your respective personal data.
        </span>
        <span className="T9" />
      </p>
      <p className="P6">
        <span className="T9"> </span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T12">(2) Legitimate Interest</span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T9">
          Within the scope of the necessary consideration of interests, we have
          weighed your interest in the respective secrecy of your personal data
          and our interests in the provision of this website and the
          establishment of contact with each other. Your interest in
          confidentiality withdraws in both cases. Otherwise, we may not provide
          this website to you or respond to your contact request.
        </span>
        <span className="T9" />
      </p>
      <p className="P6">
        <span className="T9"> </span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T12">(3) Recipients</span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T9">
          We use service providers to provide our website. For this purpose, we
          transmit personal data to these service providers. These service
          providers are contractually obliged by us to exercise the same care in
          handling personal data as we ourselves.
        </span>
        <span className="T9" />
      </p>
      <p className="P6">
        <span className="T9"> </span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T9">
          B. If you allow the use of cookies and analysis tools in your browser
        </span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T9">
          We use cookies and analysis tools to provide our website.
        </span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T9">
          So-called “cookies” are used on our websites. Cookies are data records
          that are stored by a web server on the user’s end device (e.g.
          computer, smartphone, tablet). These will be sent back to our website
          (“First Party Cookie”) or to another website to which the cookie
          belongs (“Third Party{" "}
        </span>
        <span className="T9">
          Cookie”) when you return to our website on the same device. The stored
          and returned information (e.g. Internet browser and operating system
          used; domain name of the website from which you came; average time
          spent on the site, pages accessed) enables the respective website to
          recognize that you have already accessed and visited it with the
          browser of your mobile device. When you access our websites,
          information is automatically collected in an anonymous form. In this
          way, we can enable the user to use our websites more effectively and
          generally better over time by improving the content/content and making
          it easier to use. The statistical data obtained help us to improve the
          performance and attractiveness of our websites. We use this
          information to provide you with services and information that is more
          specifically tailored to your needs. Any storage of personal data over
          and above the above-mentioned information will only take place with
          your express consent. Among other things, this is the case when you
          contact us. Your personal data will be used exclusively for the
          processing of your respective request. Our cookies remain on your
          device for five years, unless you delete them beforehand. Turning off
          the cookie function in your browser does not restrict the use of our
          websites and the services offered.
        </span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T9">
          We also use Google Analytics, a web analysis service of Google Inc.
          “(“Google”). Google Analytics uses “cookies”, which are text files
          placed on your device, to help the website analyze how users use the
          site. The information generated by the cookie about your use of this
          website is usually transferred to a Google server in the USA and
          stored there. By activating IP anonymization, however, Google will
          shorten your IP address within member states of the European Union or
          in other signatory states to the Agreement on the European Economic
          Area.
        </span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T9">
          Only in exceptional cases will the full IP address be transmitted to a
          Google server in the USA and shortened there. On our behalf, Google
          will use this information to evaluate your use of the website, to
          compile reports on website activity and to provide us with other
          services relating to website and Internet use. The IP address
          transmitted by your browser in the context of Google Analytics is not
          merged with other Google data.
        </span>
        <span className="T9" />
      </p>
      <p className="P6">
        <span className="T9"> </span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T9">
          You may refuse the use of cookies by selecting the appropriate
          settings on your browser, however please note that if you do this you
          may not be able to use the full functionality of our website. You can
          also prevent Google from collecting the data generated by the cookie
          and relating to your use of the website (including your IP address)
          and from processing this data by Google by downloading and installing
          the browser plug-in available under the following link:{" "}
        </span>
        <a
          href="http://tools.google.com/dlpage/gaoptout?hl=de"
          className="Internet_20_link"
        >
          <span className="Internet_20_link">
            <span className="T9">
              http://tools.google.com/dlpage/gaoptout?hl=de
            </span>
          </span>
        </a>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T9">
          You can prevent Google Analytics from collecting your data by clicking
          on the following link. An opt-out cookie is set which prevents the
          collection of your data on future visits to this website: Disable
          Google Analytics
        </span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T9">
          We continue to use Google Analytics to evaluate data from double-click
          cookies and AdWords for statistical purposes. If you do not want this
          to happen, you can disable it from the Ads Preferences Manager.
        </span>
        <span className="T9" />
      </p>
      <p className="P5">
        <a
          href="http://www.google.com/settings/ads/onweb/?hl=de"
          className="Internet_20_link"
        >
          <span className="Internet_20_link">
            <span className="T9">
              http://www.google.com/settings/ads/onweb/?hl=de
            </span>
          </span>
        </a>
        <span className="T9" />
      </p>
      <p className="P6">
        <span className="T9"> </span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T9">
          The legal basis for the use of cookies and analysis tools is our
          legitimate interest. We have considered your interest in maintaining
          the confidentiality of your personal data and our interest in
          providing this website (including its functions) within the framework
          of the necessary balancing of interests.{" "}
        </span>
        <span className="T12">
          Your interest in confidentiality is withdrawn in each case.
        </span>
        <span className="T12" />
      </p>
      <p className="P6">
        <span className="T12"> </span>
        <span className="T12" />
      </p>
      <p className="P10"> </p>
      <p className="P5">
        <span className="T9">
          3. Which rights as a data subject do you have?
        </span>
        <span className="T9" />
      </p>
      <p className="P9"> </p>
      <p className="P5">
        <span className="T9">
          a. To exercise your rights and to revoke your consent, please contact
          the above-mentioned contact details.{" "}
        </span>
        <span className="T12">Rights as a data subject</span>
        <span className="T12" />
      </p>
      <p className="P5">
        <span className="T9">
          You have the following rights towards us regarding your personal data:
        </span>
        <span className="T9" />
      </p>
      <p className="P6">
        <span className="T9"> </span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T12">• Right to access</span>
        <span className="T12" />
      </p>
      <p className="P5">
        <span className="T9">
          • Right to rectification or erasure of personal data
        </span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T12">• Right to restriction of processing</span>
        <span className="T12" />
      </p>
      <p className="P5">
        <span className="T12">• Right of object against processing</span>
        <span className="T12" />
      </p>
      <p className="P5">
        <span className="T12">• Right to data portability</span>
        <span className="T12" />
      </p>
      <p className="P10"> </p>
      <p className="P5">
        <span className="T12">b. Revocation of your consent</span>
        <span className="T12" />
      </p>
      <p className="P5">
        <span className="T9">
          You can revoke your consent at any time with effect for the future.
        </span>
        <span className="T9" />
      </p>
      <p className="P9"> </p>
      <p className="P5">
        <span className="T9">4. How long do we store your personal data?</span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T9">
          We delete your personal data if the respective purpose of storage no
          longer applies and no legal regulation requires storage. Statistical
          evaluations are only carried out in an anonymous form.
        </span>
        <span className="T9" />
      </p>
      <p className="P6">
        <span className="T9"> </span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T9">
          5. What possibilities to complaint do you have?
        </span>
        <span className="T9" />
      </p>
      <p className="P5">
        <span className="T9">
          You have the opportunity to complain to a data protection supervisory
          authority about our processing of your personal data.
        </span>
        <span className="T9" />
      </p>
    </div>
  );
};

export default ImprintPage;
