import React from 'react';
import { connect } from 'react-redux';

import TextInput from '../../Inputs/TextInput';
import ColorInput from '../../Inputs/ColorInput';
import DropdownInput from '../../Inputs/DropdownInput';
import AdminButtons from '../utils/AdminButtons'

import SidebarItem from '../../SidebarItem';

import { updateItemGraphQL } from '../../../../redux/actions';


// Form applicable form title, bold_text, medium_text

class LinksForm extends React.Component {
  constructor(props) {
    // props: textarea:bool, name:String
    super(props)

    this.state = { ...props.state }
  }

  get value() {
    return this.state
  }

  onDragOver(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  onDrop(e, that) {
    if (e.dataTransfer.getData('linkedItem') != null) {
      let linkedItem = JSON.parse(e.dataTransfer.getData('linkedItem'))
      if (linkedItem.itemType === 'page' && linkedItem.subType !== 'landing' && linkedItem.order > 6) {
        let linkedItemOrder = linkedItem.order
        let lastItem = that.props.pages[this.props.pages.length - 1]
        linkedItem.order = lastItem.order
        lastItem.order = linkedItemOrder
        this.props.updateOrder(lastItem)
        this.props.updateOrder(linkedItem)
      }
    }
  }

  render() {
    // Needs to be replaced later on. Whenever FontSelection is finished
    const fontOptions = [
      { name: "Bryant Bold", value: "Bryant Bold" },
      { name: "Bryant Medium", value: "Bryant Medium" }
    ]
    return (
      <div className="form text_form">
        <h2>{this.props.name}</h2>
        <div className="inputs">
          <DropdownInput
            options={fontOptions}
            name="Font"
            value={this.state.font}
            onChange={value => this.setState({ font: value })}
          />
          <TextInput
            name="Size"
            value={this.state.size}
            onChange={value => this.setState({ size: value })}
          />
          <ColorInput
            name="Font Colour"
            initialInput={true}
            value={this.state.color}
            onChange={value => this.setState({ color: value })}
          />
          <ColorInput
            name="Highlight Colour"
            initialInput={true}
            value={this.state.highlightColor}
            onChange={value => this.setState({ highlightColor: value })}
          />
        </div>
        <div
          // TODO: Doesn't do anything atm that's why it's commented out. Reasses in the future.
          // onDragOver={this.onDragOver}
          // onDrop={e => this.onDrop(e, this)}
        >
          <SidebarItem
            depth={0}
            expanded={this.props.expanded}
            item={{
              title: "Links",
              children: this.props.pages
            }}
          />
        </div>

        <AdminButtons noDelete={true} item={this.state} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    pages: state.pages.items
  }
}

const mapDispatchToProps = dispatch => ({
  updateOrder: (newItem) => {
    dispatch(updateItemGraphQL(newItem.itemType, newItem))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(LinksForm);
