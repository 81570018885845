import { combineReducers } from 'redux';

import actionTypes from './actions';
import { getIndexOfId, findItem, filterItemsByItemType } from '../utils/filters';


function general(state = [], action) {
  let newItems = [...state]

  if (action.itemType === 'general') {
    switch(action.type) {
      case actionTypes.FETCH_ITEMS:
        if (action.items.items.length > 0) newItems = action.items.items
        break
      case actionTypes.UPDATE_ITEM:
        for (let index = 0; index < newItems.length; index++) {
          if (newItems[index].id === action.item.id) {
            action.item.children = newItems[index].children
            newItems[index] = action.item
          }
        }
        break
      default:
        break
    }
  }
  return newItems
}

function pages(state = {items: [], lastEvaluatedKey: ""}, action) {
  let oldState = {...state}
  let newItems = [...state.items]

  if (action.itemType === 'page') {
    switch (action.type) {
      case actionTypes.SORT_ITEMS:
        newItems.map((item, index) => item.order = action.sortedChildren[index].order)
        break
      case actionTypes.CREATE_ITEM:
        action.item.children = []
        newItems.push(action.item)
        break
      case actionTypes.UPDATE_ITEM:
        for (let index = 0; index < newItems.length; index++) {
          if (newItems[index].id === action.item.id) {
            action.item.children = newItems[index].children
            newItems[index] = action.item
          }
        }
        break
      case actionTypes.DELETE_ITEM:
        for (let index = 0; index < newItems.length; index++) {
          if (newItems[index].id === action.id) {
            newItems.splice(index, 1)
          }
        }
        break
      case actionTypes.FETCH_ITEMS:
        newItems = newItems.concat(action.items.items)
        break
      case actionTypes.FETCH_DETAILS:
        for (let index = 0; index < newItems.length; index++) {
          if (newItems[index].id === action.item.id) {
            newItems[index] = action.item
          }
        }
        break
      default:
        break
    }
  }

  else if (action.itemType === 'box') {
    let item
    let parentItem
    switch(action.type){
      case actionTypes.SORT_ITEMS:
        parentItem = findItem(newItems, action.sortedChildren[0].parentId)
        parentItem.children.map((item, index) => item.order = action.sortedChildren[index].order)
        break
      case actionTypes.CREATE_ITEM:
        parentItem = findItem(newItems, action.item.parentId)
        parentItem.children.push(action.item)
        break
      case actionTypes.UPDATE_ITEM:
        const pageIdIndex = getIndexOfId(newItems, action.item.parentId)
        if (pageIdIndex != null) {
          const boxIdIndex = getIndexOfId(newItems[pageIdIndex].children, action.item.id)
          action.item.children = newItems[pageIdIndex].children[boxIdIndex].children
          newItems[pageIdIndex].children[boxIdIndex] = action.item
        }
        break
      case actionTypes.DELETE_ITEM:
        item = findItem(newItems, action.id)
        parentItem = findItem(newItems, item.parentId)
        parentItem.children.splice(parentItem.children.findIndex(item => item.id === action.id), 1)
        break
      default:
        break
    }
  }

  else if (action.itemType === 'title' ||
           action.itemType === 'background' ||
           action.itemType === 'separation_line' ||
           action.itemType === 'bold_text' ||
           action.itemType === 'medium_text')
  {
    let parentItem
    if (action.type === actionTypes.UPDATE_ITEM) {
      let oldItem = findItem(newItems, action.item.id)
      if (oldItem != null){
        Object.keys(action.item).map(key => (
          oldItem[key] = action.item[key]
        ))
      }
    }
    else if (action.type === actionTypes.CREATE_ITEM) {
      parentItem = findItem(newItems, action.item.parentId)
      parentItem.children.push(action.item)
    }
  }

  newItems = JSON.parse(JSON.stringify(newItems))
  if (action.itemType === 'page') return {items: newItems, lastEvaluatedKey: action.lastEvaluatedKey}
  else return {items: newItems, lastEvaluatedKey: oldState.lastEvaluatedKey}
}

function projects(state = {items: [], lastEvaluatedKey: ""}, action) {
  let oldState = {...state}
  let newItems = [...state.items]

  if (action.itemType === 'project') {
    switch (action.type) {
      case actionTypes.SORT_ITEMS:
        newItems.map((item, index) => item.order = action.sortedChildren[index].order)
        break
      case actionTypes.CREATE_ITEM:
        newItems.push(action.item)
        break
      case actionTypes.UPDATE_ITEM:
        for (let index = 0; index < newItems.length; index++) {
          if (newItems[index].id === action.item.id) {
            action.item.children = newItems[index].children
            newItems[index] = action.item
          }
        }
        break
      case actionTypes.DELETE_ITEM:
        for (let index = 0; index < newItems.length; index++) {
          if (newItems[index].id === action.id) {
            newItems.splice(index, 1)
          }
        }
        break
      case actionTypes.FETCH_ITEMS:
        newItems.push(...action.items.items)
        break
      case actionTypes.FETCH_DETAILS:
        for (let index = 0; index < newItems.length; index++) {
          if (newItems[index].id === action.item.id) {
            newItems[index] = action.item
          }
        }
        break  
      default:
        break
    }
  }
  else if (action.itemType === 'project_content') {
    const projectIdIndex = getIndexOfId(newItems, action.type !== actionTypes.SORT_ITEMS ? action.item.parentId : action.sortedChildren[0].parentId)

    switch(action.type){
      case actionTypes.SORT_ITEMS:
        if (projectIdIndex != null) {
          const project = newItems[projectIdIndex]
          const projectContent = filterItemsByItemType(project.children, 'project_content')
          const projectFeatures = filterItemsByItemType(project.children, 'project_feature')
          project.children = action.sortedChildren.map((item) => {return {...item, children: [...findItem(projectContent, item.id).children]}})
          project.children.push(...projectFeatures)
        }
        break
      case actionTypes.CREATE_ITEM:
        if (projectIdIndex != null) {
          if (newItems[projectIdIndex].children === null) newItems[projectIdIndex] = [action.item]
          else newItems[projectIdIndex].children.push(action.item)
        }
        break
      case actionTypes.UPDATE_ITEM:
        if (projectIdIndex != null) {
          const containerIdIndex = getIndexOfId(newItems[projectIdIndex].children, action.item.id)
          action.item.children = newItems[projectIdIndex].children[containerIdIndex].children
          newItems[projectIdIndex].children[containerIdIndex] = action.item
        }
        break
      case actionTypes.DELETE_ITEM:
        if (projectIdIndex != null) {
          const containerIdIndex = getIndexOfId(newItems[projectIdIndex].children, action.item.id)
          newItems[projectIdIndex].children.splice(containerIdIndex, 1)
        }
        break
      default:
        break
    }
  }

  else if (action.itemType === 'title' ||
           action.itemType === 'background' ||
           action.itemType === 'separation_line' ||
           action.itemType === 'bold_text' ||
           action.itemType === 'medium_text' ||
           action.itemType === 'source')
  {
    let parentItem
    let item
    if (action.type === actionTypes.UPDATE_ITEM) {
      let oldItem = findItem(newItems, action.item.id)
      if (oldItem != null){
        let children = oldItem.children
        Object.keys(action.item).map(key => (
          oldItem[key] = action.item[key]
        ))
        oldItem.children = children
      }
    }
    else if (action.type === actionTypes.CREATE_ITEM) {
      parentItem = findItem(newItems, action.item.parentId)
      parentItem.children.push(action.item)
    }
    else if (action.type === actionTypes.DELETE_ITEM) {
      item = findItem(newItems, action.id)
      parentItem = findItem(newItems, item.parentId)
      parentItem.children.splice(parentItem.children.findIndex(item => item.id === action.id), 1)
    }
  }
  
  newItems = JSON.parse(JSON.stringify(newItems))
  if (action.itemType === 'project' && action.type === actionTypes.FETCH_ITEMS) return {items: newItems, lastEvaluatedKey: action.lastEvaluatedKey}
  else return {items: newItems, lastEvaluatedKey: oldState.lastEvaluatedKey}
}

const rootReducer = combineReducers({
  pages,
  projects,
  general
})

export default rootReducer
