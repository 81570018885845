import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import ContentRows from '../ContentRows/ContentRows'
import { filterItemsByFlagType } from '../../utils/filters';

class FlagPage extends React.Component {

  render() {
    return (
      <>
        <ContentRows
          link_type="projects"
          contents={filterItemsByFlagType(this.props.projects)}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  projects: [...state.projects.items]
})

export default connect(mapStateToProps)(withRouter(FlagPage));
