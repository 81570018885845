import React from 'react';
import { connect } from 'react-redux';

// Forms for Box and its children
import BoxForm from './Forms/Box/BoxForm';
import TextForm from './Forms/utils/TextForm';
import TextFieldForm from './Forms/Box/TextFieldForm';
import BackgroundForm from './Forms/Box/BackgroundForm';
import SeparationLineForm from './Forms/Box/SeparationLineForm';

import PageForm from './Forms/Page/PageForm';

import ProjectForm from './Forms/Project/ProjectForm';
import ProjectContentForm from './Forms/Project/ProjectContentForm';
import ProjectSourceForm from './Forms/Project/ProjectSourceForm';

import GeneralForm from'./Forms/General/GeneralForm';
import NavForm from'./Forms/General/NavForm';
import LinksForm from'./Forms/General/LinksForm';

import { createItemWithSourceGraphQL, updateItemGraphQL, deleteItemGraphQL, fetchItemsGraphQL } from '../../redux/actions';

import './AdminForm.scss';

class AdminForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        items: [...this.props.items],
    }
  }

  render() {
    let forms = []

    for (let i=0; i<this.state.items.length; i++) {
      let item = this.state.items[i]
      switch(item.itemType) {
        case 'box':
          forms.push(<BoxForm state={item} />)
          break
        case 'text_field':
          forms.push(<TextFieldForm state={item} />)
          break
        case 'separation_line':
          forms.push(<SeparationLineForm state={item} noDelete={this.props.parentItemType !== 'project'}/>)
          break
        case 'bold_text':
          forms.push(<TextForm state={item} name="Text Headlines" textarea={true} />)
          break
        case 'medium_text':
          forms.push(<TextForm state={item} name="Text Sublines" textarea={true} />)
          break
        case 'title':
          forms.push(<TextForm state={item} name="Headline"/>)
          break
        case 'background':
          forms.push(<BackgroundForm state={item} />)
          break;
        case 'page':
          forms.push(<PageForm state={item} />)
          break
        case 'project':
          forms.push(<ProjectForm state={item} />)
          break
        case 'project_content':
          switch(item.subType) {
            case 'separation_line':
              forms.push(<SeparationLineForm state={item} noDelete={false}/>)
              break
            default:
              forms.push(<ProjectContentForm state={item} />)
          }
          break
        case 'source':
          switch(item.parentType) {
            case 'feature':
              break
            case 'blue_box':
              break;
            default:
              forms.push(<ProjectSourceForm state={item} noDelete={(item.parentType === 'feature' || item.parentType === 'blue_box' || item.parentType === 'separation_line')}/>)
              break
          }
          break
        case 'general':
          switch(item.subType) {
            case 'main':
              forms.push(<GeneralForm state={item} />)
              break
            case 'nav':
              forms.push(<NavForm state={item} />)
              break
            case 'links':
              forms.push(<LinksForm state={item} />)
              break
            default:
              break
          }
          break
        default:
          break
      }
    }



    return (
      <div className="admin_form">
        {
          forms.map((form, index) => (
            <React.Fragment key={index}>
              { form }
            </React.Fragment>
          ))
        }
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
    createSource: (parentId, file) => dispatch(createItemWithSourceGraphQL('source', parentId, file)),
    fetchSources: (parentId) => dispatch(fetchItemsGraphQL({itemType: 'source', parentId: parentId})),
    updateItem: (itemType, newItem) => dispatch(updateItemGraphQL(itemType, newItem)),
    deleteItem: (itemType, item) => dispatch(deleteItemGraphQL(itemType, item))
})

export default connect(null, mapDispatchToProps)(AdminForm);
