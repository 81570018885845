import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, useLocation, Switch, Route, Link } from 'react-router-dom';

import NavBar from './components/NavBar/NavBar';
import ProjectPage from './components/ProjectPage/ProjectPage';
import AdminSidebar from './components/AdminSidebar/AdminSidebar';
import Login from './components/Login/Login';
import StandardPage from './components/StandardPage/StandardPage';
import KeywordResultsPage from './components/KeywordResultsPage/KeywordResultsPage';
import FlagPage from './components/FlagPage/FlagPage';
import ImprintPage from './components/ImprintPage/ImprintPage';

import  { fetchItemsGraphQL, fetchItemsGraphQLShallow } from './redux/actions';

import './App.scss';

const AppDiv = React.forwardRef((props, ref) => (
  <div className="App" ref={ref} {...props}></div>
));

const ImprintLink = (props) => {
  const location = useLocation()
  
  if (location.pathname != '/imprint') {
    return (
      <Link to='/imprint'>
        <footer>
          IMPRINT & DATA PRIVACY
        </footer>
      </Link>
    )
  }
  return (<></>)
}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.appRef = React.createRef()

    this.state = {appRef: {}, initiated: false}
  }

  componentDidMount() {
    if (!this.state.initiated) {
      this.props.fetchPages()
      this.props.fetchGeneral()
      this.props.fetchProjects()  
    }
    this.setState({appRef: this.appRef, initiated: true})
  }

  render (){
    return (
      <BrowserRouter>
        <AppDiv ref={this.appRef}>
          <NavBar />
          {process.env.REACT_APP_NODE_ENV != 'prod' && <AdminSidebar />}
          <Switch>
            <Route exact path='/login'>
              <Login app={this.state.appRef.current}/>
            </Route>
            <Route exact path='/'>
              <StandardPage app={this.state.appRef.current}/>
            </Route>
            <Route exact path='/results/:keyword' component={KeywordResultsPage} />
            <Route exact path='/keywords/:id' component={StandardPage} />
            <Route exact path='/pages/:id' component={StandardPage} />
            <Route exact path='/projects/:id' component={ProjectPage} />
            <Route ecact path='/flagged/:id' component={FlagPage} />
            <Route ecact path='/imprint' component={ImprintPage} />
          </Switch>
        </AppDiv>
        <ImprintLink />
      </BrowserRouter>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  fetchGeneral: () => dispatch(fetchItemsGraphQL({itemType: 'general', parentId: process.env.REACT_APP_USER_ID, userIsParent: true, unsorted: true})),
  fetchPages: () => dispatch(fetchItemsGraphQLShallow({itemType: 'page', parentId: process.env.REACT_APP_USER_ID, userIsParent: true})),
  fetchProjects: () => dispatch(fetchItemsGraphQLShallow({itemType: 'project', parentId: process.env.REACT_APP_USER_ID, userIsParent: true})),  
})

export default connect(null, mapDispatchToProps)(App);
