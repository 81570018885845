import React from 'react';
import styled from 'styled-components';

import { convertPtToRem } from '../../utils/functions';

import './ProjectTextColumn.scss';

class ProjectTextColumn extends React.Component {
  render() {

    const BoldText = styled.h1`
                    ${this.props.boldText.font != null ? `font: ${this.props.boldText.font} !important;` : ''}
                    ${this.props.boldText.size != null ? `font-size: ${convertPtToRem(this.props.boldText.size)}rem !important;` : ''}
                    ${this.props.boldText.color != null ? `color: #${this.props.boldText.color} !important;` : ''}
                    &:hover {
                      ${this.props.boldText.highlightColor != null ? `color: #${this.props.boldText.highlightColor} !important;` : ''}
                    }
                  `
              
    const MediumText = styled.p`
      ${this.props.mediumText.font != null ? `font: ${this.props.mediumText.font} !important;` : ''}
      ${this.props.mediumText.size != null ? `font-size: ${convertPtToRem(this.props.mediumText.size)}rem !important;` : ''}
      ${this.props.mediumText.color != null ? `color: #${this.props.mediumText.color} !important;` : ''}
      &:hover {
        ${this.props.mediumText.highlightColor != null ? `color: #${this.props.mediumText.highlightColor} !important;` : ''}
      }
    `
    let boldTextTitle=this.props.boldText.title
      ? this.props.boldText.title :
      'This is a placeholder. Please configure the headline text to display something different'
    
    let mediumTextTitle=this.props.mediumText.title
      ? this.props.mediumText.title :
      'This is a placeholder. Please configure the subline text to display something different'

    return (
      <div className='project_text_column'>
        <BoldText dangerouslySetInnerHTML={{__html: boldTextTitle}}/>
        <MediumText dangerouslySetInnerHTML={{__html: mediumTextTitle}}/>
        <p className='project_text_column__tags'>
          {this.props.tags && this.props.tags.map((tag, index) => {
            if (index !== this.props.tags.length - 1) return `#${tag}, `;
            else return `#${tag}`
          })}
        </p>
      </div>
    )
  }
}

export default ProjectTextColumn;
