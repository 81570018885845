import React from 'react';
import { connect } from 'react-redux';

import ContentOverlay from './ContentOverlays/ContentOverlay/ContentOverlay';
import ContentOverlayNews from './ContentOverlays/ContentOverlayNews/ContentOverlayNews';
import ContentOverlayTransparent from './ContentOverlays/ContentOverlayTransparent/ContentOverlayTransparent';
import ContentOverlayTransparentBig from './ContentOverlays/ContentOverlayTransparentBig/ContentOverlayTransparentBig';
import DragAndDrop from '../DragAndDrop/DragAndDrop';

import { updateItemGraphQL, fetchDetailsGraphQL } from '../../redux/actions';
import { filterItemsByItemType, filterItemsById, filterItemsBySubType } from '../../utils/filters'

import './ContentContainer.scss';



class ContentContainer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      project: null
    }
  }

  onDrop(e, item, callable) {
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      let background = filterItemsByItemType(item.children, 'background')[0]
      background.source = e.dataTransfer.files[0]
      background.sourceType = 'img'
      callable(background)
    }
    if (e.dataTransfer.getData('linkedItem') != null ) {

      let linkedItem = JSON.parse(e.dataTransfer.getData('linkedItem'))
      if (linkedItem.itemType === 'project') {
        item.subType = 'project'
      }
      else {
        item.subType = 'default'
      }
      item.link = linkedItem.itemType === 'project' ?
        `/projects/${linkedItem.id}` :
        linkedItem.itemType === 'page' ?
        linkedItem.subType === 'keyword' ?
        `keywords/${linkedItem.id}` :
        `pages/${linkedItem.id}` :
        null
      callable(item).then(async (_) => {
        if (item.subType === 'project') {
          if(linkedItem.children == null) {
            linkedItem = await this.props.fetchProjectDetails(linkedItem.id, linkedItem.parentId)
          }
          this.setState({project: linkedItem})
        }
      })
    }
  }

  componentDidMount() {
    const item = this.props.item

    if (item.link?.includes('project')) {
      let project = filterItemsById(this.props.projects, item.link.split('projects/')[1])
      if (project?.children == null && project != null) {
        this.props.fetchProjectDetails(project.id, project.parentId).then((res) => this.setState({ project: res }))
      }
    }
  }

  render(){
    const item = this.props.item

    if (this.state.project?.children != null) {
      item.children = filterItemsBySubType(this.state.project.children, this.props.overlay_type)[0]?.children
    }  

    let background, title, separation_line, bold_text, medium_text

    if (item.children != null) {
      background = filterItemsByItemType(item.children, 'background')[0]
      title = filterItemsByItemType(item.children, 'title')[0]
      // text_field = filterItemsByItemType(item.children, 'text_field')[0]
      separation_line = filterItemsByItemType(item.children, 'separation_line')[0]
      bold_text = filterItemsByItemType(item.children, 'bold_text')[0]
      medium_text = filterItemsByItemType(item.children, 'medium_text')[0]
    } 

    const background_element = typeof background === 'undefined' ?
    <img src={item.source} alt="Container background"/>
    :
    !background.source ?
    <img src='test_images/16:9_placeholder.png' alt="Placeholder"/> :
      background.sourceType === "maps" ?
        <div className='maps_wrapper'>
          <iframe
            title="Google Maps Container"
            frameborder="0" style={{border:0}}
            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}
              &q=${background.source}`} allowfullscreen>
          </iframe>
        </div>
      : <img src={background.source} alt="Container background"/>
    
    let containerComponent = item.children == null ? (<div className= 'content_container'></div>) :
    (
      <div
        className={`content_container ${typeof(this.props.overlay_type) !== 'undefined' ? this.props.overlay_type : '' }`}
        style={this.props.style}>
        {background_element}
        {this.props.overlay_type === 'transparent' ?
        <ContentOverlayTransparent
          boldText={bold_text}
          mediumText={medium_text}
        /> :
        this.props.overlay_type === 'big_transparent' ?
        <ContentOverlayTransparentBig
          header_text={title}
          content_bold={bold_text}
          content_medium={medium_text}
        /> :
        this.props.overlay_type === 'news' ?
        <ContentOverlayNews
          header_text={title}
          content_bold={bold_text}
          content_medium={medium_text}
        />
      : this.props.overlay_type != null ? <ContentOverlay
         link_type={this.props.link_type}
         link={item.link}
         header_text={title}
         content_bold={bold_text}
         content_medium={medium_text}
         separation_line={separation_line}
         flag={this.state.project?.flagType}
        /> : null
         }
      </div>
    )

    if (!this.props.noDnD) {
      return (
        <DragAndDrop handleDrop={e => this.onDrop(e, this.props.item, this.props.updateItem)}>
          {containerComponent}
        </DragAndDrop>
      )
    }
    else {
      return (
        <>
          {containerComponent}
        </>
      )
    }
  }
}

const mapStateToProps = state =>({
  projects: [...state.projects.items]
})

const mapDispatchToProps = dispatch => ({
  updateItem: (newItem) => dispatch(updateItemGraphQL(newItem.itemType, newItem)),
  fetchProjectDetails: (projectId, parentId) => dispatch(fetchDetailsGraphQL(projectId, parentId, 'project'))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContentContainer);
