import axios from 'axios';

const parse = require('html-react-parser');

const orderItems = (a, b) => (a.order > b.order) ? -1 : 1

function escapeRegExp(string) {
  return string.replace(/[."'*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

async function getVimeoEmbed(link) {
  let res = await axios.get(`https://vimeo.com/api/oembed.json?url=${link}`).catch(error => console.log(error));
  return parse(res.data.html)
}

const convertPtToRem = (ptValue) => {
  return ptValue/12
}

export {
  orderItems,
  escapeRegExp,
  getVimeoEmbed,
  convertPtToRem
}
