import React from 'react';

import SourceInput from '../../Inputs/SourceInput';
import ColorInput from '../../Inputs/ColorInput';
import TextInput from '../../Inputs/TextInput';
import AdminButtons from '../utils/AdminButtons';


class NavForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {...props.state}
  }

  get value() {
    return this.state
  }

  render() {
    return(
      <div className="form nav_form">
        <h2>Background</h2>
        <div className="inputs">
          <SourceInput
            sourceType={this.state.sourceType}
            source={this.state.source}
            options={[{name: 'Image', value:'img'}, {name: 'Colour',value:'color'}]}
            colorName="Background Colour"
            initialInput={this.state.sourceType}
            onChange={value => this.setState({source: value.source, sourceType: value.sourceType})}
            />
        </div>
        <h2>Separation line</h2>
        <div className="inputs">
          <TextInput
           name="Size"
           value={this.state.size}
           onChange={value => this.setState({size: value})}
          />
          <ColorInput
            name="Colour"
            initialInput={true}
            value={this.state.color}
            onChange={value => this.setState({color: value}) }
          />
        </div>
        <AdminButtons noDelete={true} item={this.state} />
      </div>
    )
  }
}

export default NavForm;
